import { faPlus } from '@fortawesome/pro-light-svg-icons';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, PageHeader, Segment, toast } from '../../../../RbKit';
import api from '../../../../api';
import { ApiLesson } from '../../../../api/lesson';
import ChangeHandler from '../../../../components/ChangeHandler';
import { ApiModule } from '../../../../api/module';
import ChapterListView from '../ChapterView';
import ContentEditor from '../../../../modules/ContentEditor';

type TabType = 'content' | 'chapters';

interface LessonEditProps extends RouteComponentProps<{ id?: string, moduleId: string }> {
}

const LessonEditView: FC<LessonEditProps> = ({ history, match }) => {
    const { id, moduleId } = match.params;
    const [ rmodule, setModule ] = useState<ApiModule>();
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ lesson, setLesson ] = useState<Partial<ApiLesson>>({});
    const [ changesMade, setChangesMade ] = useState<boolean>(false);
    const [ tab ] = useState<TabType>('content');

    useEffect(() => {
        api.getModule(parseInt(moduleId)).then(({ data }) => {
            setModule(data);
            setLesson({
                moduleId: data.id,
            });
        });
    }, [moduleId]);

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getLesson(parseInt(id)).then(({ data }) => {
                setLesson(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        setTimeout(() => fetch(), 300);
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setLesson({
            ...lesson,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putLesson({
            ...lesson,
            timeOnPage: lesson.timeOnPage || 0,
        }).then(({ data }) => {
            setChangesMade(false);
            setIsLoading(false);
            toast('Lesson saved succesfully');
            history.push(`/modules/${data.moduleId}/lessons/${data.id}/edit`);
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Something went wrong', 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/modules': 'Modules',
                [`/modules/${moduleId}/edit`]: `${rmodule?.name}`,
                [`/modules/${moduleId}/lessons/${id ? `${id}/edit` : 'create'}`]: id ? lesson.name || 'New lesson' : 'New lesson',
            }}
            title={`${id ? 'Edit' : 'Create'} lesson`}
        />

        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment isLoading={isLoading}>
                <Form.Input
                    error={errors.name}
                    label="Title"
                    name="name"
                    onChange={handleInput}
                    required
                    value={lesson.name || ''}
                />
                <div style={{ maxWidth: 250 }}>
                    <Form.Input
                        info="0 = No limit"
                        label="Min. time on page (seconds)"
                        name="timeOnPage"
                        onChange={handleInput}
                        type="number"
                        value={lesson.timeOnPage || ''}
                    />
                </div>
            </Segment>

            {tab === 'content' && (
                <ContentEditor
                    topLevel
                    content={{
                        blocks: lesson.content,
                        settings: lesson.settings,
                    }}
                    onUpdate={(content) => {
                        handleInput({ name: 'content', value: content });
                        setChangesMade(true);
                    }}
                    onSettingsUpdate={(id, values) => {
                        setLesson({
                            ...lesson,
                            settings: {
                                ...(lesson.settings || {}),
                                [id]: values,
                            },
                        });
                        setChangesMade(true);
                    }}
                />
            )}
        </Form>

        {lesson.id && tab === 'chapters' && (
            <div style={{ marginTop: '2rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                    <h3 style={{ margin: 0 }}>Chapters</h3>
                    <Button
                        label="Add chapter"
                        href={`/modules/${moduleId}/lessons/${id}/chapters/create`}
                        icon={faPlus}
                        primary
                    />
                </div>
                <ChapterListView lesson={lesson as ApiLesson} />
            </div>
        )}
    </>);
}

export default LessonEditView;
