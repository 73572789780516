import { faVimeoSquare } from '@fortawesome/free-brands-svg-icons';
import { faPause, faPlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { RefObject } from 'react';
import { getValue } from '../../../../lib/block';
import { secToMin } from '../../../../lib/util';
import { BlockProps, Segment } from '../../../../RbKit';
import styles from './styles.module.scss';

const parseVimeoId = (url: string) => {
    const split = url.split('?')[0].replace(/\/$/, '').split('/');
    return split[split.length - 1];
}

interface BlockState {
    keyPoint: any,
    keyPointDone: boolean,
    duration: number,
    isPlaying: boolean,
    seconds: number,
    progress: number,
}

class Block extends React.Component<BlockProps, BlockState> {
    private player: any;
    private progressRef: RefObject<HTMLDivElement> = React.createRef();

    constructor (props: BlockProps) {
        super(props);

        this.state = {
            keyPoint: undefined,
            keyPointDone: false,
            duration: 0,
            isPlaying: false,
            progress: 0,
            seconds: 0,
        };
    }

    componentDidMount = (): void => {
        const iframe = document.querySelector('iframe');
        this.player = new (window as any).Vimeo.Player(iframe);
        this.player.on('play', () => this.setState({ isPlaying: true }));
        this.player.on('pause', () => this.setState({ isPlaying: false }));
        this.player.on('loaded', async () => {
            const d = await this.player.getDuration();
            this.setState({ duration: d });
        });
        this.player.on('timeupdate', ({ duration, seconds }: any) => {
            const { keyPoint } = this.state;
            this.setState({ progress: seconds });
            this.setState({ duration, seconds });

            if (keyPoint !== undefined && seconds >= keyPoint) {
                this.setState({ keyPointDone: true });
                window.dispatchEvent(new CustomEvent('video-keypoint', { detail: { keyPointDone: true, keyPoint }}));
            }
        });

        let keyPoint = getValue(this.props.data, 'keyPoint');
        keyPoint = keyPoint === '' || keyPoint === null ? undefined : keyPoint;
        this.setState({ keyPoint });

        if (keyPoint !== undefined) {
            window.dispatchEvent(new CustomEvent('video-keypoint', { detail: { keyPoint }}));
        }
    }

    togglePlay = (): void => {
        const { isPlaying } = this.state;
        this.setState({ isPlaying: !isPlaying });

        if (isPlaying) {
            this.player.pause();
        } else {
            this.player.play();
        }
    }

    handleSeek = (e: React.MouseEvent): void => {
        if (!this.progressRef.current) return;
        const { duration, keyPoint, keyPointDone, seconds } = this.state;
        const rect = this.progressRef.current.getBoundingClientRect();
        const newSeconds = ((e.clientX - rect.x) / rect.width) * duration;
        
        if (this.props.lessonDone || keyPoint === undefined || keyPointDone || newSeconds < seconds) {
            this.player.setCurrentTime(newSeconds);
        }
    }

    render = (): JSX.Element => {
        const { data, front, lessonDone } = this.props;
        const { duration, isPlaying, keyPoint, keyPointDone, progress, seconds } = this.state;

        const video = (
            <div className={`${styles.container}`}>
                <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    className={styles.video}
                    src={`https://player.vimeo.com/video/${parseVimeoId(getValue(data, 'videoId'))}`}
                    title="Vimeo Video"
                />
            </div>
        );

        return front ? (
            <div className={styles.wrapper}>
                {video}
                <div className={styles.controls}>
                    <div
                        className={styles.controlsButton}
                        onClick={() => this.togglePlay()}
                    >
                        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                    </div>
                    <div
                        className={styles.progressContainer}
                        onClick={this.handleSeek}
                        style={{ cursor: lessonDone || keyPoint === undefined || keyPointDone ? 'pointer': 'default' }}
                    >
                        <div
                            className={styles.controlsProgress}
                            ref={this.progressRef}
                        >
                            <div
                                className={`${styles.bar} ${styles.bar2}`}
                                style={{ width: `${duration > 0 ? (seconds / duration) * 100 : 0}%` }}
                            />
                            <div
                                className={styles.bar}
                                style={{ width: `${duration > 0 ? (progress / duration) * 100 : 0}%` }}
                            />
                            {keyPoint !== undefined && !keyPointDone && !lessonDone && (
                                <div 
                                    className={styles.keyPoint}
                                    style={{ left: `${keyPoint === 0 ? 100 : (duration > 0 ? (keyPoint / duration) * 100 : 0)}%` }}
                                >
                                    <div className={styles.tooltip}>
                                        U moet minimaal tot hier kijken
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.controlsTime}>
                        {secToMin(seconds)} / {secToMin(duration)}
                    </div>
                </div>
                {keyPoint !== undefined && !keyPointDone && !lessonDone && (
                    <div className="global-warning" style={{ marginTop: -24 }}>
                        <p><b>Let op:</b> U moet minimaal {secToMin(keyPoint)} van de video bekeken hebben om door te kunnen gaan.</p>
                    </div>
                )}
            </div>
        ) : (
            <Segment>
                {video}
            </Segment>
        );
    }
}

const data = {
    Block,
    icon: faVimeoSquare,
    getData: () => ({
        block: 'video',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'keyPoint',
            type: 'text',
            label: 'Must watch till (seconds)',
            value: '',
        }, {
            id: 'videoId',
            type: 'text',
            label: 'Vimeo URL',
            value: 'https://vimeo.com/259411563',
        }],
    })
};

export default data;
