import { faPlus, faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import api from '../../../api';
import { ApiMeta } from '../../../api/meta';
import { useMeta } from '../../../lib/meta';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast } from '../../../RbKit';
import { deleteMeta } from '../../../reducers/meta';
import { AppState } from '../../../store';

const MetaListView: FC = (): JSX.Element => {
    const [ query, setQuery ] = useState<string>('');
    const isLoading = useSelector((state: AppState) => state.meta.isFetching);
    const items = useMeta();
    const dispatch = useDispatch();

    const deleteItem = (metaId: number): void => {
        api.deleteMeta(metaId).then(() => {
            toast('Meta deleted successfully');
            dispatch(deleteMeta(metaId));
        });
    }

    const handleSearch = (o: ApiMeta): boolean => {
        const reg = new RegExp(query, 'i');
        return reg.test(o.name);
    }

    return (<>
        <PageHeader title="Meta" breadcrumb={{[`/admin/meta`]: 'Overview'}}>
            <Button
                href={`/admin/meta/create`}
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>
        <Segment isLoading={isLoading}>
            <Table.Actions onSearch={(q: string) => setQuery(q)} />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Label
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {items.length > 0 ? items.filter(handleSearch).map((item) => (
                        <Table.Row key={`row-${item.id}`}>
                            <Table.Cell primary name="Name">
                                <Link to={`/admin/meta/${item.id}/edit`}>
                                    {item.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell collapsing actions>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Edit"
                                        href={`/admin/meta/${item.id}/edit`}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content={`Are you sure you wish to delete this meta? This action cannot be undone.`}
                                        onConfirm={() => deleteItem(item.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Delete"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
    </>);
}

export default MetaListView;
