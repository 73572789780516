import { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, ApiSeo, request } from './';

export interface ApiPage extends ApiSeo {
    id: number,
    name: string,
    content: any[],
    settings?: { [key: string]: any },
    redirect?: string,
}

const rest = {
    delete: (pageId: number | string): AxiosPromise => {
        return request.delete(`pages/${pageId}`);
    },
    get: (pageId: number): AxiosPromise<ApiPage> => {
        return request.get(`pages/${pageId}`);
    },
    getBySlug: (slug?: string): AxiosPromise<ApiPage> => {
        return request.get(`front/pages${slug ? `/${slug}` : ''}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiPage[]>> => {
        return request.get(`pages?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (page: Partial<ApiPage>): AxiosPromise<ApiPage> => {
        return request.post(`pages${page.id ? `/${page.id}` : ''}`, page);
    },
}

export default rest;
