import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Confirm, Menu, PageHeader, Segment, Table, toast } from '../../../RbKit';
import api from '../../../api';
import { ApiProgress } from '../../../api/progress';
import moment from 'moment';

const ProgressListView: FC = (): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ items, setItems ] = useState<ApiProgress[]>([]);

    const fetch = (query?: string): void => {
        setIsLoading(true);

        api.listProgress({ query }).then(({ data }) => {
            setItems(data);
            setIsLoading(false);
        });
    }

    const deleteProgress = (progressId: number): void => {
        api.deleteProgress(progressId).then(() => {
            fetch();
            toast('Progress deleted successfully');
        });
    }

    return (<>
        <PageHeader title="Progress" breadcrumb={{'/admin/progress': 'Overview'}} />
        <Segment isLoading={isLoading}>
            <Table.Actions
                autoLoad
                onSearch={fetch}
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Name
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Module
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing  align="right">
                            Progress
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            Started
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            Last update
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {items.length > 0 ? items.map((item) => (
                        <Table.Row key={`row-${item.id}`}>
                            <Table.Cell primary name="Name">
                                <Link to={`/admin/users/${item.user?.id}/edit`}>
                                    {item.user?.firstName} {item.user?.lastName}
                                </Link>
                            </Table.Cell>
                            <Table.Cell name="Module">
                                <Link to={`/admin/modules/${item.module?.id}/edit`}>
                                    {item.module?.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell collapsing align="right">
                                {item.percentage}%
                            </Table.Cell>
                            <Table.Cell collapsing name="Started">
                                {moment(item.createdAt).format('DD/MM/YYYY - HH:mm')}
                            </Table.Cell>
                            <Table.Cell collapsing name="Last update">
                                {moment(item.updatedAt).format('DD/MM/YYYY - HH:mm')}
                            </Table.Cell>
                            <Table.Cell collapsing actions>
                                <Menu dropdown>
                                    <Confirm
                                        content="Are you sure you wish to delete this progress? This action cannot be undone"
                                        onConfirm={() => deleteProgress(item.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Delete"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
    </>);
}

export default ProgressListView;
