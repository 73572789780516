import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { useProfile } from '../../../lib/auth';
import { openAuthModal } from '../AuthModal';

interface ProtectedLinkProps extends LinkProps {
    disabled?: boolean
}

const ProtectedLink: FC<ProtectedLinkProps> = ({ children, disabled, onClick, ...props }): JSX.Element => {
    const profile = useProfile();

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        if (disabled) {
            return e.preventDefault();
        }

        if (!profile) {
            openAuthModal(props.to as string);
            return e.preventDefault();
        } else if (onClick) {
            onClick(e);
        }
    };

    return (
        <Link
            onClick={handleClick}
            {...props}
        >
            {children}
        </Link>
    );
}

export default ProtectedLink;
