import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Checkbox, DropdownOption, Form, PageHeader, Segment, toast } from '../../../RbKit';
import api, { ApiSeo } from '../../../api';
import { ApiModule } from '../../../api/module';
import ChangeHandler from '../../../components/ChangeHandler';
import LessonListView from './LessonView';
import { faPlus, faRetweet } from '@fortawesome/pro-light-svg-icons';
import ContentEditor from '../../../modules/ContentEditor';
import MetaInput from '../../admin/MetaView/MetaInput';
import SeoView from '../../../modules/Seo';

interface ModuleEditProps extends RouteComponentProps<{ id?: string }> {
}

const ModuleEditView: FC<ModuleEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ rmodule, setModule ] = useState<Partial<ApiModule>>({ isActive: true, isPublic: false });
    const [ changesMade, setChangesMade ] = useState<boolean>(false);
    const [ sortMode, setSortMode ] = useState<boolean>(false);
    const [ quizOptions, setQuizOptions ] = useState<DropdownOption[]>([]);

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getModule(parseInt(id), 'metaOptions.parent').then(({ data }) => {
                setModule(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        api.listQuizzes({ limit: 999 }).then(({ data }) => {
            setQuizOptions(data.data.map((o) => ({
                text: o.name,
                value: o.id,
            })));
        });

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setModule({
            ...rmodule,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putModule(rmodule).then(({ data }) => {
            setChangesMade(false);
            setIsLoading(false);
            toast('Module saved succesfully');
            if (!id) {
                history.push(`/modules/${data.id}/edit`);
            } else {
                fetch();
            }
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Something went wrong', 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/modules': 'Modules',
                [`/modules/${id ? `${id}/edit` : 'create'}`]: id ? rmodule.name || 'New' : 'New',
            }}
            title={`${id ? 'Edit' : 'Create'} module`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment isLoading={isLoading}>
                <div style={{ display: 'flex' }}>
                    <Form.Group>
                        <Checkbox
                            checked={rmodule.isActive}
                            label="Active"
                            onChange={({ checked }: any) => handleInput({ name: 'isActive', value: checked })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Checkbox
                            checked={rmodule.isPublic}
                            label="Public"
                            onChange={({ checked }: any) => handleInput({ name: 'isPublic', value: checked })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Checkbox
                            checked={rmodule.isProfessional}
                            label="Only for professionals"
                            onChange={({ checked }: any) => handleInput({ name: 'isProfessional', value: checked })}
                        />
                    </Form.Group>
                </div>
                <Form.Input
                    error={errors.name}
                    label="Title"
                    name="name"
                    onChange={handleInput}
                    required
                    value={rmodule.name || ''}
                />
                <Form.Input
                    label="Deeplink"
                    name="deeplink"
                    onChange={handleInput}
                    placeholder="https://"
                    value={rmodule.deeplink || ''}
                />
                <Form.Textarea
                    label="Excerpt"
                    name="excerpt"
                    rows={4}
                    onChange={handleInput}
                    value={rmodule.excerpt || ''}
                />
                <Form.Dropdown
                    label="Scored test"
                    name="quizId"
                    onChange={handleInput}
                    options={quizOptions}
                    value={rmodule.quizId || ''}
                />
                <MetaInput
                    selected={rmodule.meta}
                    onChange={(meta) => handleInput({ name: 'meta', value: meta})}
                />
            </Segment>
            <SeoView data={rmodule as ApiSeo} handleInput={handleInput} />
            <ContentEditor
                topLevel
                content={{
                    blocks: rmodule.content,
                    settings: rmodule.settings,
                }}
                onUpdate={(content) => {
                    handleInput({ name: 'content', value: content });
                    setChangesMade(true);
                }}
                onSettingsUpdate={(id, values) => {
                    setModule({
                        ...rmodule,
                        settings: {
                            ...(rmodule.settings || {}),
                            [id]: values,
                        },
                    });
                    setChangesMade(true);
                }}
            />
        </Form>

        {rmodule.id && (
            <div style={{ marginTop: '2rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                    <h3 style={{ margin: 0 }}>Lessons</h3>
                    <div>
                        <Button
                            label="Add lesson"
                            href={`/modules/${rmodule.id}/lessons/create`}
                            icon={faPlus}
                            primary
                        />
                        <Button
                            icon={faRetweet}
                            label="Sort"
                            onClick={() => setSortMode(true)}
                        />
                    </div>
                </div>
                <LessonListView
                    rmodule={rmodule as ApiModule}
                    sortMode={sortMode} 
                    onStopSort={() => setSortMode(false)}
                />
            </div>
        )}
    </>);
}

export default ModuleEditView;
