import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { ApiLesson } from '../api/lesson';
import { ApiModule } from '../api/module';
import { fetchCurrentLesson, fetchCurrentModule } from '../reducers/progress';
import { AppState } from '../store';
import { useProfile } from './auth';

export const useCurrentLesson = (lessonId: number, force?: boolean): ApiLesson | undefined => {
    const dispatch = useDispatch();
    const currentLesson = useSelector((state: AppState) => state.progress.currentLesson);
    
    if (currentLesson === undefined || currentLesson.id !== lessonId || force) {
        dispatch((dispatch: Dispatch, getState: any) => fetchCurrentLesson(dispatch, getState, lessonId));
        return undefined;
    }

    return currentLesson;
}

export const useCurrentModule = (moduleId?: number, force?: boolean): ApiModule | undefined => {
    const dispatch = useDispatch();
    const currentModule = useSelector((state: AppState) => state.progress.currentModule);
    
    if (moduleId !== undefined && (currentModule === undefined || currentModule.id !== moduleId || force)) {
        dispatch((dispatch: Dispatch, getState: any) => fetchCurrentModule(dispatch, getState, moduleId));
        return undefined;
    }

    return currentModule;
}

export const useObjectProgress = (type: string, typeId: number): any[] => {
    const [ obj, setObj ] = useState<ApiModule | ApiLesson>();
    const [ status, setStatus ] = useState<boolean>();
    const profile = useProfile();

    useEffect(() => {
        if (!profile) return;
        const progress: any = find(profile?.progress, { type, typeId } as any);

        if (progress) {
            setObj(progress);
            setStatus(progress.percentage >= 100);
        } else {
            setObj(undefined);
            setStatus(undefined);
        }
    }, [profile, type, typeId]);

    return [ obj, status ];
}

export const useProgress = () => {
    return useSelector((state: AppState) => state.progress);
}

export const useDeeplink = () => {
    return useSelector((state: AppState) => state.progress.isDeeplink);
}
