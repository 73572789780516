import { faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Confirm, Menu, Segment, Table, toast, useTableSort } from '../../../../RbKit';
import api from '../../../../api';
import { ApiChapter } from '../../../../api/chapter';
import { ApiLesson } from '../../../../api/lesson';

interface LessonListViewProps {
    lesson: ApiLesson,
}

const ChapterListView: FC<LessonListViewProps> = ({ lesson }): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ orderBy, order, setSorting ] = useTableSort('firstName');
    const [ chapters, setChapters ] = useState<ApiChapter[]>([]);

    const fetch = useCallback((query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listChapters(lesson.id, { query, page, limit: 999 }).then(({ data }) => {
            setChapters(data.data);
            setIsLoading(false);
        });
    }, [lesson]);
    useEffect(() => fetch(), [orderBy, order, fetch]);

    const deleteChapter = (chapterId: number | string): void => {
        api.deleteChapter(chapterId).then(() => {
            fetch();
            toast('Chapter deleted successfully');
        });
    }

    return (
        <Segment isLoading={isLoading}>
            <Table.Actions
                onSearch={fetch}
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell
                            sorted={[orderBy, 'name']}
                            order={order}
                            onSort={setSorting}
                        >
                            Name
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {chapters.length > 0 ? chapters.map((chapter) => (
                        <Table.Row key={`row-${lesson.id}`}>
                            <Table.Cell primary name="Name">
                                <Link to={`/modules/${chapter.moduleId}/lessons/${chapter.lessonId}/chapters/${chapter.id}/edit`}>
                                    {chapter.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell collapsing actions>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Edit"
                                        href={`/modules/${chapter.moduleId}/lessons/${chapter.lessonId}/chapters/${chapter.id}/edit`}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Are you sure you wish to delete this chapter? This action cannot be undone"
                                        onConfirm={() => deleteChapter(chapter.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Delete"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>);
}

export default ChapterListView;
