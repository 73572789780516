import { faIconsAlt } from '@fortawesome/pro-regular-svg-icons';
import React, { FC } from 'react';
import { getValue } from '../../../../lib/block';
import { BlockProps, Segment } from '../../../../RbKit';
import ImageThumb from '../../../../components/ImageThumb';
import styles from '../Text/styles.module.scss';

const Block: FC<BlockProps> = ({ data }): JSX.Element => {
    return (
        <Segment>
            <ImageThumb
                square
                image={getValue(data, 'image')}
                detail
            />
            <div className={styles.container}>
                {getValue(data, 'label')}
            </div>
        </Segment>
    );
}

const data = {
    Block,
    icon: faIconsAlt,
    getData: () => ({
        block: 'icon',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'image',
            type: 'image',
            label: 'Icon image',
            value: '',
        }, {
            id: 'label',
            type: 'text',
            label: 'Label',
            value: '',
        }, {
            id: 'link',
            type: 'text',
            label: 'Link',
            value: '',
        }],
    })
};

export default data;
