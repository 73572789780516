import { faCheck, faCircleNotch, faLockOpen } from '@fortawesome/pro-light-svg-icons';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ApiLesson } from '../../../api/lesson';
import { useObjectProgress } from '../../../lib/progress';
import ProtectedLink from '../ProtectedLink';
import styles from './styles.module.scss';
import { useProfile, hasRole } from '../../../lib/auth';
import Button from '../Button';

interface LessonLinkProps {
    lesson: ApiLesson,
    protect?: boolean,
    button?: boolean,
}

const LessonLink: FC<LessonLinkProps> = ({ button, children, lesson, protect }): JSX.Element => {
    const [ progressObj, progress ] = useObjectProgress('lesson', lesson.id);
    const profile = useProfile();

    const renderIcon = (): JSX.Element => {
        let className = 'warning-override';
        let icon = faLock;
        
        if (lesson.position === 0 || protect === false || hasRole(profile, 'Accreditor')) {
            className = '';
            icon = faLockOpen;

            if (button && !progress) {
                return (
                    <Button
                        primary
                        label="Start"
                    />
                )
            }
        }
        
        if (progressObj) {
            className = progress ? 'success' : '';
            icon = progress ? faCheck : faCircleNotch;

            if (!progress && button) {
                return (
                    <Button
                        primary
                        label="Hervat"
                    />
                )
            }
        }

        return (
            <FontAwesomeIcon
                icon={icon}
                className={className}
            />
        );
    }

    const props = {
        className: styles.link,
        disabled: progress === undefined && lesson.position !== 0 && !hasRole(profile, 'Accreditor'),
        to: `/modules/${lesson.moduleId}/lessons/${lesson.id}`,
    }

    const kids = children || <>
        <div>
            <h3>Lesblok {lesson.position + 1}</h3>
            <p>{lesson.name}</p>
        </div>
        {renderIcon()}
    </>

    return protect
        ? <ProtectedLink {...props}>{kids}</ProtectedLink>
        : <Link {...props}>{kids}</Link>;
}

export default LessonLink;
