import Accordion from './Accordion';
import Answer from './Answer';
import Columns from './Columns';
import Divider from './Divider';
import FlipText from './FlipText';
import Icon from './Icon';
import Image from './Image';
import ImageGallery from './ImageGallery';
import ImageText from './ImageText';
import Pinpoint from './Pinpoint';
import Label from './Label';
import Modules from './Modules';
import ModuleMeta from './ModuleMeta';
import Question from './Question';
import Quiz from './Quiz';
import Quote from './Quote';
import Section from './Section';
import Text from './Text';
import Video from './Video';

export const BLOCKS: any = {
    Accordion,
    Answer,
    Columns,
    Divider,
    Fliptext: FlipText,
    Icon,
    Image,
    Imagegallery: ImageGallery,
    Imagetext: ImageText,
    Label,
    Modules,
    Modulemeta: ModuleMeta,
    Pinpoint,
    Question,
    Quiz,
    Quote,
    Section,
    Text,
    Video,
};

export const blockComponents: any = {
    accordion: Accordion.Block,
    answer: Answer.Block,
    columns: Columns.Block,
    divider: Divider.Block,
    fliptext: FlipText.Block,
    icon: Icon.Block,
    image: Image.Block,
    imagegallery: ImageGallery.Block,
    imagetext: ImageText.Block,
    label: Label.Block,
    modules: Modules.Block,
    modulemeta: ModuleMeta.Block,
    pinpoint: Pinpoint.Block,
    question: Question.Block,
    quiz: Quiz.Block,
    quote: Quote.Block,
    section: Section.Block,
    text: Text.Block,
    video: Video.Block,
};
