import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ApiModule } from '../../../api/module';
import { useObjectProgress } from '../../../lib/progress';
import Button from '../Button';
import ModuleMeta from '../ModuleMeta';
import styles from './styles.module.scss';

interface ModuleBlockProps {
    rmodule: ApiModule,
}

const ModuleBlock: FC<ModuleBlockProps> = ({ rmodule }): JSX.Element => {
    const history = useHistory();
    const progress = useObjectProgress('module', rmodule.id);

    const handleClick = (): void => {
        history.push(`/modules/${rmodule.id}`);
    }

    return (
        <div
            className={styles.container}
            onClick={handleClick}
        >
            <div
                className={styles.image}
            >
                <div>
                    <span>{rmodule.info?.createdAt}</span>
                </div>
            </div>
            <div className={styles.info}>
                {progress && progress[0] && progress[0].percentage > 0 && (
                    <div className={styles.progress}>
                        <div className={styles.innerProgress}>
                            <div style={{ width: `${progress[0].percentage}%` }} />
                        </div>
                        {Math.round(progress[0].percentage)}%
                    </div>
                )}
                <div>
                    <div className={styles.progress}>
                    </div>
                    <h2>{rmodule.name}</h2>
                    {rmodule.excerpt && (
                        <p>
                            {rmodule.excerpt}
                        </p>
                    )}
                    <ModuleMeta rmodule={rmodule} />
                </div>
                <Button
                    primary
                    label={progress && progress[0] && progress[0].percentage > 0 ? 'Hervatten' : 'Start'}
                />
            </div>
        </div>
    );
}

export default ModuleBlock;
