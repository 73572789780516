import { find } from 'lodash';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { ApiMeta } from '../../../../api/meta';
import api from '../../../../api';
import  { Form, DropdownOption } from '../../../../RbKit';
import styles from './styles.module.scss';

interface MetaInputProps {
    onChange: (meta: ApiMeta[]) => void,
    selected?: Partial<ApiMeta>[],
}

const MetaInput: FC<MetaInputProps> = ({ onChange, selected }): JSX.Element => {
    const [ meta, setMeta ] = useState<ApiMeta[]>([]);
    const [ selection, setSelection ] = useState<Partial<ApiMeta>[]>(selected || []);
    useEffect(() => setSelection(selected || []), [selected]);

    const handleAutoComplete = (query: string, callback: (options: DropdownOption[]) => void) => {
        if (query.length < 3) return;
        api.autoCompleteMeta(query).then(({ data }) => {
            setMeta(data.data);

            callback(data.data.map((o) => ({
                text: `${o.parent?.name}: ${o.name}`,
                value: o.id,
            })));
        });
    }

    const handleChange = ({ value }: any): void => {
        const opt = find(meta, { id: value });
        if (opt && !find(selection, { id: value })) {
            const newSelection = [ ...selection, opt ];
            setSelection(newSelection);
            onChange(newSelection as ApiMeta[]);
        }
    }

    const removeMeta = (index: number): void => {
        const newSelection = [ ...selection ].filter((o, i) => i !== index);
        setSelection(newSelection);
        onChange(newSelection as ApiMeta[]);
    }

    return (
        <div className={styles.container}>
            <Form.Dropdown
                autoComplete={handleAutoComplete}
                label="Meta"
                onChange={handleChange}
            />
            <div>
                {selection.map((opt: Partial<ApiMeta>, index: number) => (
                    <span
                        className={styles.tag}
                        style={opt.parent?.color ? { backgroundColor: opt.parent?.color } : {}}
                        key={index}
                        onClick={() => removeMeta(index)}
                    >
                        {opt.parent?.name}: {opt.name}
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                ))}
            </div>
        </div>
    );
}

export default MetaInput;
