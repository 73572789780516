import React, { FC, useCallback, useEffect, useState, useRef } from 'react';
import sortBy from 'lodash/sortBy';
import api from '../../../../api';
import { ApiQuiz, ApiQuizQuestion, ApiQuizQuestionAnswer } from '../../../../api/quiz';
import Button from '../../../../components/front/Button';
import { useProfile } from '../../../../lib/auth';
import { useObjectProgress } from '../../../../lib/progress';
import { Checkbox } from '../../../../RbKit';
import Content from '../../../front/Content';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { find } from 'lodash';

interface QuizProps {
    quiz?: ApiQuiz,
}

const Quiz: FC<QuizProps> = ({ quiz: _quiz }): JSX.Element | null => {
    const [ quiz, setQuiz ] = useState(_quiz);
    const [ index, setIndex ] = useState<number>(-1);
    const [ currentAnswer, setCurrentAnswer ] = useState<ApiQuizQuestionAnswer[]>([]);
    const [ currentQuestion, setCurrentQuestion ] = useState<ApiQuizQuestion>();
    const [ results, setResults ] = useState<any>([]);
    const [ progress ] = useObjectProgress('quiz', _quiz?.id || 0);
    const profile = useProfile();

    const checkResults = useRef<boolean>(true);

    useEffect(() => {
        if (!_quiz || !_quiz.questions) return;

        if (_quiz.showIntro) {
            setQuiz(_quiz);
            return;
        }

        if (_quiz.questions.length === 1) {
            setQuiz({
                ..._quiz,
                questions: [{
                    ..._quiz.questions[0],
                    answers: sortBy((_quiz.questions[0].answers || []), (o) => o.position || 0),
                }],
            });
        } else {
            setQuiz(_quiz);
        }

        setTimeout(() => setIndex(0), 500);
    }, [_quiz]);

    useEffect(() => {
        if (!quiz || !checkResults.current) return;
        if (progress && progress.percentage >= 100 && results.length <= 0) {
            api.front.progress.getResults(quiz.id, progress.score >= quiz.minScore || !quiz.isFinal).then(({ data }) => {
                checkResults.current = false;
                setResults(data);
            });
        } else if (progress && progress.percentage < 100 && results.length > 0) {
            setResults([]);
        }
    }, [progress, results, quiz]);

    useEffect(() => {
        if (!quiz || !quiz.questions || index < 0) return;
        setCurrentQuestion(quiz.questions[index]);
    }, [quiz, index]);
    
    const reset = useCallback((): void => {
        if (progress && quiz && quiz.questions) {
            setIndex(Math.ceil((progress.percentage / 100) * quiz.questions.length));
        } else {
            setIndex(-1);
        }
    }, [progress, quiz]);

    useEffect(() => {
        reset();
    }, [quiz, reset]);

    const retake = (): void => {
        if (!quiz) return;
        localStorage.removeItem(`quiz-session-${quiz.id}`);
        api.front.progress.retake('quiz', quiz.id).then(() => {
            api.front.getQuiz(quiz.id).then(({ data }) => {
                setQuiz(data);
            
                checkResults.current = true;
                setIndex(-1);
                setResults([]);
                setCurrentAnswer([]);
                setCurrentQuestion(undefined);
            });
        });
    }

    const next = (newIndex: number, override?: ApiQuizQuestionAnswer[]): void => {
        if (!quiz || !quiz.questions) return;

        if (newIndex < 0 || !profile) {
            setIndex(newIndex);
            return;
        }

        const nextQuestion = quiz.questions[index];
        
        if (!nextQuestion || !(override || currentAnswer)) {
            setIndex(newIndex);
            return;
        }

        api.front.progress.saveQuestion(
            quiz.id,
            nextQuestion.id,
            override || currentAnswer
        ).then(({ data }) => {
            if (!quiz || !quiz.questions) return;
            
            localStorage.setItem(`quiz-session-${quiz.id}`, data.session);
            
            api.front.progress.upsert(
                'quiz',
                quiz.id,
                Math.floor((newIndex / quiz.questions.length) * 100),
                { session: data.session }
            );

            setTimeout(() => {
                setIndex(newIndex);
            }, 500);
        });
    }

    const deleteProgress = (): void => {
        if (window.confirm('Weet je zeker dat je de voortgang van deze quiz wilt verwijderen?')) {
            if (!profile) return;
            const linked = find(profile.progress, { typeId: quiz?.id, type: 'quiz' });
            if (!linked) return;
            api.deleteProgress(linked.id).then(() => {
                localStorage.removeItem(`quiz-session-${quiz?.id}`);
                api.getProfile().then(() => {
                    window.location.reload();
                });
            });
        }
    }

    const handleAnswer = (answer: ApiQuizQuestionAnswer): void => {
        if (currentQuestion?.multiple) {
            const newAnswers = [ ...currentAnswer ];
            const check = newAnswers.find((o) => o.id === answer.id);

            if (check) {
                setCurrentAnswer(newAnswers.filter((o) => o.id !== answer.id));
            } else {
                newAnswers.push(answer);
                setCurrentAnswer(newAnswers);
            }
        } else {
            setCurrentAnswer([answer]);

            if ((quiz?.questions?.length || 0) === 1) {
                next(index + 1, [answer]);
            }
        }
    }

    if (!quiz) {
        return null;
    }

    return (
        <div className={styles.container}>
            {index === -1 && quiz.showIntro && (<>
                <Content blocks={quiz.intro} settings={{}} />
                <div className={styles.buttons}>
                    <Button
                        primary
                        label={quiz.startBtnLabel || "Start de toets"}
                        onClick={() => next(0)}
                    />
                </div>
            </>)}

            <div style={{ minHeight: 480 }}>
            {currentQuestion && (
                <div className={styles.question}>
                    {(quiz.questions?.length || 0) > 1 && (
                        <h2>
                            <small>Vraag</small>
                            {' '}{index + 1} / {quiz.questions?.length}
                        </h2>
                    )}
                    <p>
                        {currentQuestion.name}
                    </p>

                    {currentQuestion.content && <Content
                        blocks={currentQuestion.content}
                        settings={{}}
                    />}

                    <div className={styles.answers}>
                        {currentQuestion.answers?.map((answer) => {
                            const check = currentAnswer.find((o) => o.id === answer.id);

                            return (
                                <div
                                    key={`answer-${answer.id}`}
                                    className={`${styles.answer} ${check ? styles.selected : ''}`}
                                    onClick={() => handleAnswer(answer)}
                                >
                                    <div
                                        className={styles.answerCheckbox}
                                        style={{ pointerEvents: 'none' }}
                                    >
                                        <Checkbox
                                            radio={currentQuestion.multiple === false}
                                            checked={check !== undefined}
                                        />
                                    </div>
                                    <div className={styles.answerContent}>
                                        {answer.content}
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    {(quiz.questions?.length || 0) > 1 && (
                        <div className={styles.buttons}>
                            <Button
                                primary
                                label="Verder"
                                // disabled={!currentAnswer}
                                onClick={() => next(index + 1)}
                            />
                        </div>
                    )}
                </div>
            )}
            
            {progress && !currentQuestion && index !== -1 && (
                <div>
                    {progress && <Content
                        blocks={quiz[(progress.score || 0) >= quiz.minScore ? 'passed' : 'failed']}
                        settings={{}}
                    />}

                    <h1 style={{ textAlign: 'center', marginBottom: 0, fontSize: 18 }}>
                        {quiz.isFinal && `Uw score: ${progress.score || 0}%`}
                        {profile?.role !== 'User' && (
                            <span style={{ display: 'block', fontSize: 12, marginTop: 8, cursor: 'pointer' }} onClick={() => deleteProgress()}>Voortgang verwijderen</span>
                        )}
                    </h1>

                    {(progress.score || 0) >= quiz.minScore && results.map((result: any, index: number) => (
                        <div
                            key={`result-${index}`}
                            className={styles.question}
                        >
                            <h2>
                                <span className={`${styles.result} ${result.isCorrect ? '' : styles.resultNotCorrect}`}>
                                    <FontAwesomeIcon
                                        icon={result.isCorrect ? faCheck : faTimes}
                                    />
                                </span>
                                <small>Vraag</small>
                                {' '}{index + 1} / {quiz.questions?.length}
                            </h2>
                            <p>
                                {result.question.name}
                            </p>
                            {(!quiz.isFinal || progress.score >= quiz.minScore) && (
                                <div className={styles.answers}>
                                    {sortBy(result.question.answers || [], (o) => o.position).map((answer: any) => (
                                        <div
                                            key={`answer-${index}-result-${answer.id}`}
                                            className={`${styles.answer} ${answer.isCorrect
                                                ? styles.correct
                                                : (result.answers.includes(answer.id) ? styles.notCorrect : '')
                                            }`}
                                        >
                                            <div
                                                className={styles.answerCheckbox}
                                                style={{ pointerEvents: 'none' }}
                                            >
                                                <Checkbox
                                                    radio={result.question.multiple === false}
                                                    checked={result.answers.includes(answer.id)}
                                                />
                                            </div>
                                            <div className={styles.answerContent}>
                                                {answer.explanation ? (<>
                                                    <b>{answer.content}</b><br />
                                                    <em>{answer.explanation}</em>
                                                </>) : answer.content}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}

                    <div className={styles.buttons}>
                        {quiz.isFinal && progress.score < quiz.minScore && progress.tries < quiz.maxTries && (
                            <Button
                                primary
                                label={`Herkansen (poging ${progress.tries + 1} van ${quiz.maxTries})`}
                                onClick={() => retake()}
                            />    
                        )}
                        {/* {(!quiz.isFinal || progress.score >= quiz.minScore || progress.tries >= quiz.maxTries) && (
                            <Button
                                primary
                                label="Verder"
                                onClick={() => next(index + 1)}
                            />
                        )} */}
                    </div>
                </div>
            )}
            </div>
        </div>
    );
}

export default Quiz;
