import React, { FC, useCallback } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button } from '../../../RbKit';
import { ButtonProps } from '../Button';

interface CaptchaButtonProps extends ButtonProps {
    onVerify?: (token: string) => void,
}

const CaptchaButton: FC<CaptchaButtonProps> = ({ onClick, onVerify, ...props }): JSX.Element => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async (e: any) => {
        if (!executeRecaptcha) return;
        const token = await executeRecaptcha('default');
        if (onVerify) {
            onVerify(token);
        }
    }, [executeRecaptcha, onVerify]);

    return <Button onClick={handleReCaptchaVerify} {...props} />
}

const CaptchaButtonProvider: FC<CaptchaButtonProps> = (props): JSX.Element => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LciJ5seAAAAAORe7_3DLwThRSYxY8sePw0od9UL">
            <CaptchaButton {...props} />
        </GoogleReCaptchaProvider>
    );
}

export default CaptchaButtonProvider;
