import React, { FC, FormEvent, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import api, { request } from '../../../api';
import Section from '../../../components/front/Section';
import { Button, Form, Segment } from '../../../RbKit';

const ResetPasswordView: FC<RouteComponentProps> = ({ history }): JSX.Element => {
    const [ error, setError ] = useState<boolean>(false);
    const [ password, setPassword ] = useState<string>('');
    const [ passwordConfirm, setPasswordConfirm ] = useState<string>('');
    
    const send = (e: FormEvent): void => {
        e.preventDefault();
        setError(false);
        
        if (password !== passwordConfirm) {
            setError(true);
        } else {
            request.post(`auth/reset-password?${window.location.search.substring(1)}`, {
                password,
            }).then(({ data }) => {
                localStorage.setItem('accessToken', data.token as string);
                api.getProfile().then(() => history.push('/'));
            }).catch(() => {
                history.push('/');
            });
        }
    }

    return (
        <Section alt>
            <div>
                <Segment>
                    <Form onSubmit={(e: FormEvent) => send(e)}>
                        <Form.Input
                            error={error}
                            label="Wachtwoord"
                            onChange={({ value }: any) => setPassword(value)}
                            required
                            type="password"
                            value={password}
                        />
                        <Form.Input
                            error={error}
                            label="Herhaal wachtwoord"
                            onChange={({ value }: any) => setPasswordConfirm(value)}
                            required
                            type="password"
                            value={passwordConfirm}
                        />
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                            <Button
                                className="button"
                                type="submit"
                            >
                                Opslaan
                            </Button>
                        </div>
                    </Form>
                </Segment>
            </div>
        </Section>
    );
}

export default ResetPasswordView;
