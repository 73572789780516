import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

export const useDebounceSearch = (callback?: (query: string) => void, delay: number = 300) => {
    const [ debounceSearch ] = useState(() => debounce(callback ? callback : () => {}, delay));
    return debounceSearch;
};

export const useHandleKeyUp = (
	callback: (key: string, e: Event) => void,
	keys: string[],
	force: boolean = false,
): void => {
	const callbackRef = useRef<(key: string, e: Event) => void>();

	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	useEffect(() => {
		const handleKeyUp = (e: KeyboardEvent) => {
			if (
				!keys.includes(e.key) ||
				!callbackRef.current ||
				(!force && ['INPUT', 'SELECT', 'TEXTAREA'].includes(document.activeElement?.tagName || ''))
			) {
				return;
			}

			callbackRef.current(e.key, e);
		}

		window.addEventListener('keyup', handleKeyUp);
		return () => window.removeEventListener('keyup', handleKeyUp);
	}, [force, keys]);
}

export function useRefState<T>(initialValue: T): [{ current: T }, (newValue: T) => void] {
    const [ value, setValue ] = useState<T>(initialValue);
    const realValue = useRef<T>(value);

    const setRealValue = (newValue: T): void => {
        realValue.current = newValue;
        setValue(newValue);
    }

    return [realValue, setRealValue];
}

export const useWindowResize = (callback: (e: Event) => void, delay: number = 300) => {
    useEffect(() => {
        let resizeTimer: NodeJS.Timeout; 

        const handleResize = (e: Event) => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => callback(e), delay);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
}

export const useWindowScroll = (callback: (e: Event) => void, delay: number = 300, deps: any[] = []) => {
    useEffect(() => {
        let scrollTimer: NodeJS.Timeout; 
        
        const handleScroll = (e: Event) => {
            clearTimeout(scrollTimer);
            scrollTimer = setTimeout(() => callback(e), delay);
        }

        window.addEventListener('scroll', handleScroll, true);
        return () => window.removeEventListener('scroll', handleScroll, true);
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}

export const useInterval = (callback: any, delay: number | null): void => {
    const callbackRef = useRef();

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        const tick = (): void => {
            const cb: any = callbackRef.current;
            cb();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
