import React, { FC, useEffect, useState } from 'react';
import api from '../../../api';
import { useProfile } from '../../../lib/auth';
import { Button, Form, Message, Modal } from '../../../RbKit';

export const openAuthModal = (redirect: string): void => {
    dispatchEvent(new CustomEvent('open-auth-modal', { detail: { redirect } }));
}

const AuthModal: FC = (): JSX.Element | null => {
    const [ emailAddress, setEmailAddress ] = useState<string>('');
    const [ errors, setErrors ] = useState<any>({});
    const [ open, setOpen ] = useState<boolean>(false);
    const [ password, setPassword ] = useState<string>('');
    const [ redirect, setRedirect ] = useState<string>('/');
    const profile = useProfile();

    useEffect(() => {
        const handleOpen = ({ detail }: any): void => {
            setRedirect(detail.redirect);
            setOpen(true);
        }

        window.addEventListener('open-auth-modal', handleOpen, true);
        return () => window.removeEventListener('open-auth-modal', handleOpen, true);
    }, []);

    const handleClose = (): void => {
        setOpen(false);
        setEmailAddress('');
        setPassword('');
    }

    const login = (): void => {
        if (emailAddress === '' || password === '') {
            setErrors({ emailAddress: true, password: true });
            return;
        }

        setErrors({});

        api.login(emailAddress, password).then(({ data }) => {
            localStorage.setItem('accessToken', data.token as string);
            api.getProfile().then(() => {
                window.location.href = redirect;
            });
        }).catch(() => {
            setErrors({ incorrect: true });
        });
    }

    if (profile) {
        return null;
    }

    return (
        <Modal
            header="Inloggen"
            open={open}
            onClose={handleClose}
            size="small"
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label="Annuleren"
                        link
                        onClick={() => handleClose()}
                    />
                    <Button
                        label="Registreren"
                        link
                        onClick={() => handleClose()}
                        href={'/registreren'}
                    />
                    <Button
                        label="Inloggen"
                        primary
                        onClick={login}
                    />
                </div>
            )}
        >
            {errors.incorrect && (
                <Message
                    content="Verkeerde gegevens"
                    error
                />
            )}
            <Form.Input
                error={errors.emailAddress}
                label="E-mailadres"
                onChange={({ value }: any) => setEmailAddress(value)}
                type="email"
                value={emailAddress}
            />
            <Form.Input
                error={errors.password}
                label="Wachtwoord"
                onChange={({ value }: any) => setPassword(value)}
                type="password"
                value={password}
            />
        </Modal>
    );
}

export default AuthModal;
