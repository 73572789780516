import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Form, PageHeader, Segment, Table, toast } from '../../../RbKit';
import api from '../../../api';
import { ApiUser } from '../../../api/user';
import ChangeHandler from '../../../components/ChangeHandler';
import { ApiBig } from '../../../api/big';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';

interface UserEditProps extends RouteComponentProps<{ id?: string }> {
}

let bigTimer: any = undefined;

const UserEditView: FC<UserEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ user, setUser ] = useState<Partial<ApiUser>>({});
    const [ changesMade, setChangesMade ] = useState<boolean>(false);
    const [ bigError, setBigError ] = useState<string>();
    const [ bigData, setBigData ] = useState<ApiBig>();

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getUser(parseInt(id)).then(({ data }) => {
                setUser(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setUser({
            ...user,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putUser(user).then(() => {
            setChangesMade(false);
            setIsLoading(false);
            toast('User saved succesfully');
            history.push('/admin/users');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Something went wrong', 'error')
        });
    }

    const checkBig = (): void => {
        setBigError(undefined);
        if (!user.bigNumber) return;
        api.checkBig(user.bigNumber.trim()).then(({ data }) => {
            setBigData(data);
            handleInput({ name: 'isProfessional', value: data.isPractioner});
        }).catch(() => setBigError('Dit BIG nummer is niet bekend in het BIG-register'));
    }

    const handleBigCheck = (): void => {
        clearTimeout(bigTimer);
        bigTimer = setTimeout(() => {
            if (!user.bigNumber || user.bigNumber.length < 9) return;
            api.checkBig(user.bigNumber.trim()).then(({ data }) => {
                setBigData(data);
                handleInput({ name: 'isProfessional', value: data.isPractioner});
            }).catch(() => setBigError('Dit BIG nummer is niet bekend in het BIG-register'));
        }, 500);
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/admin/users': 'Users',
                [`/admin/users/${id ? `${id}/edit` : 'create'}`]: id ? user.firstName || 'New' : 'New',
            }}
            title={`${id ? 'Edit' : 'Create'} user`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment isLoading={isLoading}>
                <Form.Dropdown
                    label="Rol"
                    name="role"
                    onChange={handleInput}
                    options={[{
                        text: 'Administrator',
                        value: 'Administrator',
                    }, {
                        text: 'User',
                        value: 'User',
                    }, {
                        text: 'Accreditor',
                        value: 'Accreditor',
                    }]}
                    required
                    value={user.role}
                />
                <Form.Input
                    error={errors.firstName}
                    label="First name"
                    name="firstName"
                    onChange={handleInput}
                    required
                    value={user.firstName || ''}
                />
                <Form.Input
                    error={errors.lastName}
                    label="Last name"
                    name="lastName"
                    onChange={handleInput}
                    required
                    value={user.lastName || ''}
                />
                <Form.Input
                    error={errors.email_address}
                    label="Email address"
                    name="emailAddress"
                    onChange={handleInput}
                    required
                    value={user.emailAddress || ''}
                />
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
                    <div style={{ flex: 1 }}>
                        <Form.Input
                            error={bigError}
                            label="BIG number"
                            name="bigNumber"
                            onChange={handleInput}
                            onKeyUp={() => handleBigCheck()}
                            value={user.bigNumber || ''}
                        />
                    </div>
                    {user.bigNumber && user.bigNumber !== '' && (
                        <Button
                            label="Controleren"
                            onClick={() => checkBig()}
                            style={{ marginLeft: '1rem' }}
                        />
                    )}
                </div>
                <Form.Group>
                    {user.isProfessional ? (
                        <FontAwesomeIcon icon={faCheckCircle} className="success" />
                    ) : (
                        <FontAwesomeIcon icon={faTimesCircle} />
                    )}
                    <span style={{ marginLeft: 8 }}>Beroepsbeoefenaar</span>
                </Form.Group>
                {bigData && (<>
                    <h3 style={{ margin: '1rem 0 .5rem 0' }}>Gegevens zoals gevonden in het BIG-register</h3>
                    <Table small>
                        <tbody>
                            <Table.Row>
                                <Table.Cell collapsing>Naam</Table.Cell>
                                <Table.Cell>{bigData.salutation} {bigData.fullName}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell collapsing>Beroep</Table.Cell>
                                <Table.Cell>{bigData.profession}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell collapsing>Specialisme</Table.Cell>
                                <Table.Cell>{bigData.specialism}</Table.Cell>
                            </Table.Row>
                            {bigData.mention !== false && (
                                <Table.Row>
                                    <Table.Cell collapsing>Vermelding</Table.Cell>
                                    <Table.Cell>{bigData.mentionType}</Table.Cell>
                                </Table.Row>
                            )}
                            <Table.Row>
                                <Table.Cell collapsing>Beroepsbeoefenaar</Table.Cell>
                                <Table.Cell>
                                    {bigData.isPractioner && <FontAwesomeIcon icon={faCheckCircle} className="success" />}
                                </Table.Cell>
                            </Table.Row>
                        </tbody>
                    </Table>
                </>)}
            </Segment>
        </Form>
    </>);
}

export default UserEditView;
