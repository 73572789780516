import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Section from '../../../components/front/Section';

const NotFoundView: FC = (): JSX.Element => {
    return (
        <Section>
            <div>
                <h1>404 Pagina niet gevonden</h1>
                <p>
                    Helaas, de pagina die u zocht bestaat (niet) meer.<br />
                    <Link to="/">Klik hier</Link> om terug te keren
                </p>
            </div>
        </Section>
    );
}

export default NotFoundView;
