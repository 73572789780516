import qs from 'qs';
import React, { FC, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { NotificationCenter } from './RbKit';
import api from './api';
import { hasRole, useProfile } from './lib/auth';
import TopBar from './modules/TopBar';
import Drawer from './modules/Drawer';
import Loader from './RbKit/elements/Loader';
import ImageModal from './components/ImageModal';
import UserListView from './views/admin/UserView';
import UserEditView from './views/admin/UserView/Edit';
import MetaListView from './views/admin/MetaView';
import MetaEditView from './views/admin/MetaView/Edit';
import ModuleListView from './views/admin/ModuleView';
import ModuleEditView from './views/admin/ModuleView/Edit';
import LessonEditView from './views/admin/ModuleView/LessonView/Edit';
import ChapterEditView from './views/admin/ModuleView/ChapterView/Edit';
import PageListView from './views/admin/PageView';
import PageEditView from './views/admin/PageView/Edit';
import LoginView from './views/admin/auth/LoginView';
import DocumentListView from './views/admin/DocumentView';
import ImageListView from './views/admin/ImageView';
import ImageViewModal from './views/admin/ImageView/Modal';
import QuizListView from './views/admin/QuizView';
import QuizEditView from './views/admin/QuizView/Edit';
import QuizProgressView from './views/admin/QuizView/Progress';
import ProgressListView from './views/admin/ProgressView';
import TemplateListView from './views/admin/TemplateView';
import TemplateEditView from './views/admin/TemplateView/Edit';
import QuizQuestionEditView from './views/admin/QuizView/QuestionView/Edit';
import FrontApp from './FrontApp';
import styles from './styles.module.scss';

interface AppProps {
    isEmbed: boolean | number,
}

const App: FC<AppProps> = ({ isEmbed }) => {
    const [ isFront, setIsFront ] = useState<boolean>();
    const [ isLoading, setIsloading ] = useState<boolean>(true);
    const history = useHistory();
    const profile = useProfile();

    useEffect(() => {
        if (isEmbed !== false) {
            setIsloading(false);
            setIsFront(true);
        } else {
            setIsFront(!window.location.host.includes(process.env.NODE_ENV === 'development' ? '3000' : 'cms'));
            api.getProfile().catch(() => {}).finally(() => setIsloading(false));
        }
    }, [isEmbed]);

    useEffect(() => {
        const token = qs.parse(window.location.search.substring(1));
        
        if (token && token._setToken) {
            setIsloading(true);
            localStorage.setItem('accessToken', token._setToken as string);
            window.location.href = '/';
        }
    }, [history]);

    if (isFront === undefined) {
        return null;
    }

    return isFront ? (
        <FrontApp showModule={isEmbed === false ? undefined : (isEmbed === true ? undefined : isEmbed)} />
    ) : (
        <Router>
            <TopBar />
            {isLoading ? (
                <Loader />
            ) : profile ? (<>
                <Drawer />
                <main className={styles.main}>
                    <div>
                    <Switch>
                        <Route component={DocumentListView} exact path="/media/documents" />
                        <Route component={ImageListView} exact path="/media/images" />

                        <Route component={ModuleListView} exact path="/modules" />
                        <Route component={ModuleEditView} exact path="/modules/create" />
                        <Route component={ModuleEditView} exact path="/modules/:id/edit" />
                        <Route component={LessonEditView} exact path="/modules/:moduleId/lessons/create" />
                        <Route component={LessonEditView} exact path="/modules/:moduleId/lessons/:id/edit" />
                        <Route component={ChapterEditView} exact path="/modules/:moduleId/lessons/:lessonId/chapters/create" />
                        <Route component={ChapterEditView} exact path="/modules/:moduleId/lessons/:lessonId/chapters/:id/edit" />

                        <Route component={PageListView} exact path="/pages" />
                        <Route component={PageEditView} exact path="/pages/create" />
                        <Route component={PageEditView} exact path="/pages/:id/edit" />

                        <Route component={QuizListView} exact path="/quizzes" />
                        <Route component={QuizEditView} exact path="/quizzes/create" />
                        <Route component={QuizEditView} exact path="/quizzes/:id/edit" />
                        <Route component={QuizProgressView} exact path="/quizzes/:id/progress" />

                        <Route component={QuizQuestionEditView} exact path="/quizzes/:quizId/questions/create" />
                        <Route component={QuizQuestionEditView} exact path="/quizzes/:quizId/questions/:id/edit" />
                        
                        {hasRole(profile, 'Administrator') && (<>
                            <Route component={TemplateListView} exact path="/admin/templates" />
                            <Route component={TemplateEditView} exact path="/admin/templates/create" />
                            <Route component={TemplateEditView} exact path="/admin/templates/:id/edit" />

                            <Route component={ProgressListView} exact path="/admin/progress" />

                            <Route component={UserListView} exact path="/admin/users" />
                            <Route component={UserEditView} exact path="/admin/users/create" />
                            <Route component={UserEditView} exact path="/admin/users/:id/edit" />

                            <Route component={MetaListView} exact path="/admin/meta" />
                            <Route component={MetaEditView} exact path="/admin/meta/create" />
                            <Route component={MetaEditView} exact path="/admin/meta/:id/edit" />
                        </>)}
                    </Switch>
                    </div>
                </main>
                <ImageViewModal open={false} />
                <ImageModal />
            </>) : (
                <Switch>
                    <Route component={LoginView} path="*" />
                </Switch>
            )}
            <NotificationCenter />
        </Router>
    )
}

export default App;
