import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Checkbox, Form, PageHeader, Segment, toast } from '../../../../RbKit';
import api from '../../../../api';
import { ApiQuiz, ApiQuizQuestion } from '../../../../api/quiz';
import ChangeHandler from '../../../../components/ChangeHandler';
import styles from './styles.module.scss';
import SortableList, { reorder } from '../../../../components/SortableList';
import ContentEditor from '../../../../modules/ContentEditor';

interface QuizQuestionEditProps extends RouteComponentProps<{ id?: string, quizId: string }> {
}

const QuizQuestionEditView: FC<QuizQuestionEditProps> = ({ history, match }) => {
    const { id, quizId } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ quiz, setQuiz ] = useState<ApiQuiz>();
    const [ quizQuestion, setQuizQuestion ] = useState<Partial<ApiQuizQuestion>>({});
    const [ changesMade, setChangesMade ] = useState<boolean>(false);

    useEffect(() => {
        api.getQuiz(parseInt(quizId)).then(({ data }) => {
            setQuiz(data);
        });
    }, [quizId]);

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getQuizQuestion(parseInt(quizId), parseInt(id)).then(({ data }) => {
                setQuizQuestion(data);
                setIsLoading(false);
            });
        }
    }, [id, quizId])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        setTimeout(() => fetch(), 300);
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setQuizQuestion({
            ...quizQuestion,
            [name]: value,
        });
    }

    const handleAnswerInput = (index: number, { name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        let newAnswers: any[] = [...(quizQuestion.answers || [])];

        if (name === 'isCorrect' && value === true && !quizQuestion.multiple) {
            newAnswers = newAnswers.map((o) => {
                o.isCorrect = false;
                return o;
            });
        }

        newAnswers[index][name] = value;
        setQuizQuestion({
            ...quizQuestion,
            answers: newAnswers,
        });
    }

    const addAnswer = (): void => {
        const answers = (quizQuestion.answers || []);
        setChangesMade(true);
        setQuizQuestion({
            ...quizQuestion,
            answers: [
                ...answers,
                {
                    id: `new-${Math.floor(Math.random() * 100)}`,
                    content: '',
                    explanation: '',
                    isCorrect: false,
                    position: answers.length + 1,
                }
            ],
        });
    }

    const removeAnswer = (index: number): void => {
        if (!quizQuestion.answers) return;
        setChangesMade(true);
        setQuizQuestion({
            ...quizQuestion,
            answers: quizQuestion.answers.filter((o, i) => i !== index),
        });
    }

    const onDragEnd = (result: any): void => {
        if (!quizQuestion.answers || !result.destination) return;
        setChangesMade(true);

        setQuizQuestion({
            ...quizQuestion,
            answers: reorder(quizQuestion.answers, result.source.index, result.destination.index),
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putQuizQuestion(parseInt(quizId), quizQuestion).then(() => {
            setChangesMade(false);
            setIsLoading(false);
            toast('Question saved succesfully');
            history.push(`/quizzes/${quizId}/edit`);
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Something went wrong', 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/quizzes': 'Quizzes',
                [`/quizzes/${quizId}/edit`]: quiz ? quiz.name : '',
                [`/quizzes/${id ? `${id}/edit` : 'create'}`]: id ? quizQuestion.name || 'New question' : 'New question',
            }}
            title={`${id ? 'Edit' : 'Create'} question`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment isLoading={isLoading}>
                <Form.Input
                    error={errors.name}
                    label="Name"
                    name="name"
                    onChange={handleInput}
                    required
                    value={quizQuestion.name || ''}
                />
                <Form.Group>
                    <Checkbox
                        checked={quizQuestion.multiple === true}
                        label="Multiple answers possible"
                        onChange={({ checked }: any) => handleInput({ name: 'multiple', value: checked })}
                    />
                </Form.Group>
            
                <div className={styles.answerContainer}>
                    <h3>Answers</h3>
                    <Button
                        icon={faPlus}
                        label="Add answer"
                        onClick={addAnswer}
                        primary
                    />
                </div>
                <SortableList
                    items={quizQuestion.answers || []}
                    onUpdate={onDragEnd}
                    renderListItem={(answer, index) => (
                        <div className={styles.answer}>
                            <span>
                                <FontAwesomeIcon icon={faGripVertical} />
                            </span>
                            <div className={styles.answerInfo}>
                                <div>
                                    <Form.Input
                                        label="Content"
                                        name="content"
                                        onChange={(data: any) => handleAnswerInput(index, data)}
                                        value={answer.content || ''}
                                    />
                                </div>
                                <div>
                                    <Form.Input
                                        label="Explanation"
                                        name="explanation"
                                        onChange={(data: any) => handleAnswerInput(index, data)}
                                        value={answer.explanation || ''}
                                    />
                                </div>
                                <div>
                                    <Checkbox
                                        style={{ marginTop: '1rem' }}
                                        label="Correct answer"
                                        checked={answer.isCorrect}
                                        onChange={({ checked }: any) => handleAnswerInput(index, { name: 'isCorrect', value: checked })}
                                    />
                                </div>
                            </div>
                            <Button
                                icon={faTrashAlt}
                                onClick={() => removeAnswer(index)}
                                trigger
                            />
                        </div>
                    )}
                />
            </Segment>
            <ContentEditor
                content={{ blocks: quizQuestion.content }}
                onUpdate={(content) => handleInput({ name: 'content', value: content })}
            />
        </Form>
    </>);
}

export default QuizQuestionEditView;
