import React, { FC } from 'react';
import styles from './styles.module.scss';

interface SectionProps {
    alt?: boolean,
    className?: string,
    large?: boolean,
}

const Section: FC<SectionProps> = ({ alt, className, large, children }): JSX.Element => {
    return (
        <section
            className={[
                styles.section,
                alt ? styles.alt : '',
                large ? styles.large : '',
                className,
            ].join(' ')}
        >
            {children}
        </section>
    );
}

export default Section;
