import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, ApiSeo } from './';
import { ApiLesson } from './lesson';

export interface ApiModule extends ApiSeo {
    id: number,
    quizId?: number,
    deeplink?: string,
    name: string,
    excerpt?: string,
    content?: any[],
    settings?: { [key: string]: any },
    meta?: any,
    isActive: boolean,
    isPublic: boolean,
    isProfessional?: boolean,
    lessons?: ApiLesson[],
    info?: {
        lessonCount: number,
        createdAt: string,
    }
}

const rest = {
    delete: (moduleId: number | string): AxiosPromise => {
        return axios.delete(`admin/modules/${moduleId}`);
    },
    get: (moduleId: number, qWith?: string): AxiosPromise<ApiModule> => {
        return axios.get(`modules/${moduleId}${qWith ? `?with=${qWith}` : ''}`);
    },
    getFront: (moduleId: number, qWith?: string): AxiosPromise<ApiModule> => {
        return axios.get(`front/modules/${moduleId}${qWith ? `?with=${qWith}` : ''}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiModule[]>> => {
        return axios.get(`modules?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    listFront: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiModule[]>> => {
        return axios.get(`front/modules?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (rmodule: Partial<ApiModule>): AxiosPromise<ApiModule> => {
        return axios.post(`modules${rmodule.id ? `/${rmodule.id}` : ''}`, rmodule);
    },
}

export default rest;
