import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from './';
import { ApiModule } from './module';

export interface ApiLesson {
    id: number,
    moduleId: number,
    module?: ApiModule,
    lessonId: number,
    name: string,
    content?: any[],
    settings?: { [key: string]: any },
    position: number,
    timeOnPage: number,
    loggedIn: boolean,
}

const rest = {
    delete: (lessonId: number | string): AxiosPromise => {
        return axios.delete(`admin/lessons/${lessonId}`);
    },
    get: (lessonId: number): AxiosPromise<ApiLesson> => {
        return axios.get(`lessons/${lessonId}`);
    },
    getFront: (lessonId: number): AxiosPromise<ApiLesson> => {
        return axios.get(`front/lessons/${lessonId}`);
    },
    list: (
        moduleId: number,
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiLesson[]>> => {
        return axios.get(`modules/${moduleId}/lessons?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (lesson: Partial<ApiLesson>): AxiosPromise<ApiLesson> => {
        return axios.post(`lessons${lesson.id ? `/${lesson.id}` : ''}`, lesson);
    },
}

export default rest;
