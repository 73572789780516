import { Dispatch } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import api from '../api';
import { ApiMeta } from '../api/meta';


export interface MetaState {
    meta?: ApiMeta[],
    isFetching: boolean,
}

export const deleteMeta = createAction('deleteMeta');
export const setIsFetching = createAction('setIsFetching');
export const setMeta = createAction('setMeta');

export async function fetchMeta(dispatch: Dispatch, getState: any) {
    if (getState().meta.isFetching) {
        return;
    }
    dispatch(setIsFetching(true));
    const res = await api.listMeta({}, 'children');
    dispatch(setMeta(res.data.data));
    dispatch(setIsFetching(false));
}

const actions = handleActions({
    [deleteMeta.toString()]: (state, action: any) => {
        const meta = { ...state.meta || [] } as any;

        return {
            ...state,
            meta: meta.filter((o: any) => o.id !== action.payload),
        };
    },
    [setIsFetching.toString()]: (state, action: any) => ({
        ...state,
        isFetching: action.payload,
    }),
    [setMeta.toString()]: (state, action: any) => ({
        ...state,
        meta: action.payload,
    }),
}, {
    meta: undefined,
    isFetching: false,
});

export default actions;
