import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import api from '../../../api';
import { ApiPage } from '../../../api/page';
import Content from '../../../modules/front/Content';
import Loader from '../../../RbKit/elements/Loader';
import { setSettings } from '../../../reducers/contenteditor';
import { setCurrentModule } from '../../../reducers/progress';
import NotFoundView from '../NotFoundView';

interface PageViewProps extends RouteComponentProps<{ slug?: string }> {
}

const PageView: FC<PageViewProps> = ({ match }): JSX.Element => {
    const { url } = match;
    const [fetched, setFetched ] = useState<boolean>(false);
    const [page, setPage ] = useState<ApiPage>();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(setCurrentModule());
    }, [dispatch]);

    useEffect(() => {
        api.front.getPageBySlug(url.substring(1)).then(({ data }) => {
            if (data.redirect) {
                history.push(data.redirect);
                return;
            }
            setFetched(true);
            setPage(data);
            dispatch(setSettings(data.settings || {}));
        }).catch(() => {
            setFetched(true);
        });
    }, [dispatch, url]);

    if (!fetched) {
        return <Loader />
    }

    return page
        ? <Content blocks={page.content} settings={page.settings || {}} />
        : <NotFoundView />
}

export default PageView;
