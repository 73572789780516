import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form, PageHeader, Segment, toast } from '../../../../RbKit';
import api from '../../../../api';
import { ApiChapter } from '../../../../api/chapter';
import { ApiLesson } from '../../../../api/lesson';
import ChangeHandler from '../../../../components/ChangeHandler';
import ContentEditor from '../../../../modules/ContentEditor';

interface LessonEditProps extends RouteComponentProps<{ id?: string, lessonId: string, moduleId: string }> {
}

const LessonEditView: FC<LessonEditProps> = ({ history, match }) => {
    const { id, lessonId, moduleId } = match.params;
    const [ lesson, setLesson ] = useState<ApiLesson>();
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ chapter, setChapter ] = useState<Partial<ApiChapter>>({});
    const [ changesMade, setChangesMade ] = useState<boolean>(false);

    useEffect(() => {
        api.getLesson(parseInt(lessonId)).then(({ data }) => {
            setLesson(data);
            setChapter({
                moduleId: data.moduleId,
                lessonId: data.id,
            });
        });
    }, [lessonId]);

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getChapter(parseInt(id)).then(({ data }) => {
                setChapter(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        setTimeout(() => fetch(), 300);
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setChapter({
            ...chapter,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putChapter(chapter).then(() => {
            setChangesMade(false);
            setIsLoading(false);
            toast('Chapter saved succesfully');
            history.push(`/modules/${chapter.moduleId}/lessons/${chapter.lessonId}/edit`);
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Something went wrong', 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/modules': 'Modules',
                [`/modules/${moduleId}/edit`]: `${lesson?.module?.name}`,
                [`/modules/${moduleId}/lessons/${lessonId}/edit`]: lesson?.name || '',
                [`/modules/${moduleId}/lessons/${lessonId}/chapters/${id ? `${id}/edit` : 'create'}`]: id ? chapter.name || 'New chapter' : 'New chapter',
            }}
            title={`${id ? 'Edit' : 'Create'} chapter`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment isLoading={isLoading}>
                <Form.Input
                    error={errors.name}
                    label="Title"
                    name="name"
                    onChange={handleInput}
                    required
                    value={chapter.name || ''}
                />
            </Segment>
            <ContentEditor
                topLevel
                content={{
                    blocks: chapter.content,
                    settings: chapter.settings,
                }}
                onUpdate={(content) => {
                    setChapter({
                        ...chapter,
                        content,
                    });
                    setChangesMade(true);
                }}
                onSettingsUpdate={(id, values) => {
                    setChapter({
                        ...chapter,
                        settings: {
                            ...(chapter.settings || {}),
                            [id]: values,
                        },
                    });
                    setChangesMade(true);
                }}
            />
        </Form>
    </>);
}

export default LessonEditView;
