import axios from 'axios';
import auth from './auth';
import big from './big';
import chapter from './chapter';
import document from './document';
import image, { ApiImage } from './image';
import lesson from './lesson';
import meta from './meta';
import rmodule from './module';
import page from './page';
import progress from './progress';
import template from './template';
import quiz from './quiz';
import user from './user';

export const getToken = () => {
    if (localStorage.getItem('accessToken')) {
        return localStorage.getItem('accessToken');
    }
    
    return null;
}

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common = { 'X-Requested-With': 'XMLHttpRequest' };

axios.interceptors.request.use((config) => {
    const accessToken = getToken(); 
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

    config.headers['X-Deeplink'] = window.location.host;

    return config;
});

axios.interceptors.response.use((res: any) => res, (err: any) => {
    if (err.message.indexOf(401) !== -1) {
        localStorage.removeItem('accessToken');
    } else if (err.message.indexOf(403) !== -1) {
        window.location.href = '/';
    } else if (err.message.indexOf(404) !== -1 && window.location.pathname !== '/404') {
        window.location.href = '/404';
    }
    return Promise.reject(err);
});

export interface ApiMeta {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number,
}

export interface ApiSeo {
    seoTitle: string,
    seoDescription: string,
    seoUrl: string,
    seoImageId?: number,
    seoImage?: ApiImage,
    seoNoIndex: boolean,
}

export interface ApiPaginatedResponse<T> {
    data: T,
    meta: ApiMeta,
    links: {
        first: string,
        last: string,
        next?: string,
        prev?: string,
    },
}

export const request = axios;

const api = {
    checkBig: big.check,

    getProfile: auth.getProfile,
    login: auth.login,
    logout: auth.logout,
    register: auth.register,
    
    deleteChapter: chapter.delete,
    getChapter: chapter.get,
    listChapters: chapter.list,
    putChapter: chapter.put,

    deleteDocument: document.delete,
    getDocument: document.get,
    listDocuments: document.list,
    uploadDocument: document.upload,

    deleteImage: image.delete,
    getImage: image.get,
    listImages: image.list,
    putImage: image.put,
    unlinkImage: image.unlink,
    replaceImage: image.replace,
    uploadImage: image.upload,
    listImageFolders: image.listFolders,
    putImageFolder: image.putFolder,
    removeImageFolder: image.removeFolder,

    deleteLesson: lesson.delete,
    getLesson: lesson.get,
    listLessons: lesson.list,
    putLesson: lesson.put,

    autoCompleteMeta: meta.autoComplete,
    deleteMeta: meta.delete,
    listMeta: meta.list,
    putMeta: meta.put,

    deleteModule: rmodule.delete,
    getModule: rmodule.get,
    listModules: rmodule.list,
    putModule: rmodule.put,

    deletePage: page.delete,
    getPage: page.get,
    listPages: page.list,
    putPage: page.put,
    
    deleteQuiz: quiz.delete,
    deleteQuizQuestion: quiz.deleteQuestion,
    getQuiz: quiz.get,
    getQuizQuestion: quiz.getQuestion,
    listQuizzes: quiz.list,
    listQuizQuestions: quiz.listQuestions,
    putQuiz: quiz.put,
    putQuizQuestion: quiz.putQuestion,

    deleteTemplate: template.delete,
    getTemplate: template.get,
    listTemplates: template.list,
    putTemplate: template.put,

    deleteUser: user.delete,
    getUser: user.get,
    listUsers: user.list,
    putUser: user.put,

    listProgress: progress.list,
    listQuizProgress: progress.listQuiz,
    deleteProgress: progress.delete,

    front: {
        getLesson: lesson.getFront,
        
        getQuiz: quiz.getFront,

        getModule: rmodule.getFront,
        listModules: rmodule.listFront,

        getPageBySlug: page.getBySlug,

        progress,
    },
};

export default api;
