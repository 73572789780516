import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import LessonLink from '../../../components/front/LessonLink';
import Section from '../../../components/front/Section';
import { useCurrentModule } from '../../../lib/progress';
import Content from '../../../modules/front/Content';
import Loader from '../../../RbKit/elements/Loader';
import { AppState } from '../../../store';
import styles from './styles.module.scss';
import { hasRole, useProfile } from '../../../lib/auth';
import AuthForm from '../../../components/front/AuthModal/AuthForm';
import { setCurrentLesson } from '../../../reducers/progress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';

const ModuleDetailView: FC<{ showModule?: number }> = ({ showModule }): JSX.Element | null => {
    const { id } = useParams() as any;
    const rmodule = useCurrentModule(parseInt(id || showModule || undefined));
    const currentLesson = useSelector((state: AppState) => state.progress.currentLesson);
    const { pathname } = useLocation();
    const profile = useProfile();
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (pathname.includes('lessons')) return;
        dispatch(setCurrentLesson(undefined));
    }, [dispatch, pathname]);

    if (currentLesson && pathname.includes('lessons')) {
        return null;
    }

    if (!rmodule) {
        return <Loader />
    }

    return (<>
        {/* <Section>
            <div> */}
                {/* <h1 style={{ marginBottom: '.5rem' }}>{rmodule.name}</h1>
                <p>{rmodule.excerpt}</p>
                <ModuleMeta rmodule={rmodule} /> */}
                {rmodule.content && (
                    <Content blocks={rmodule.content} settings={rmodule.settings || {}} />
                )}
            {/* </div>
        </Section> */}
        <Section alt>
            {!profile && !rmodule.isPublic ? (
                <div style={{ maxWidth: 578 }}>
                    <h2 style={{ marginBottom: '1rem' }}>Inloggen</h2>
                    <p>
                        Deze website is uitsluitend bestemd voor zorgprofessionals. Om deze les te bekijken kunt u hieronder inloggen of een account aanmaken.
                    </p>
                    <AuthForm />
                </div>
            ) : (
                (hasRole(profile, 'Accreditor') || profile?.isProfessional) ? (
                    <div>
                        <div className={styles.lessons}>
                            {rmodule.lessons?.map((lesson) => (
                                <LessonLink
                                    key={`lesson-${lesson.id}`}
                                    lesson={lesson}
                                    protect={rmodule.isPublic === false}
                                    button
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    <div style={{ maxWidth: 578 }}>
                        <div className="global-warning-rb">
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                            Omdat deze e-learning uitsluitend bestemd is voor beroepsbeoefenaren kunt u hem helaas niet volgen.
                        </div>
                    </div>
                )
            )}
        </Section>
    </>);
}

export default ModuleDetailView;
