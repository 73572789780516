import { find } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMeta } from '../reducers/meta';
import { AppState } from '../store';

export const renderMeta = (
    meta: any,
    parentName: string,
    render: (option: any) => JSX.Element
): JSX.Element | undefined => {
    const option = find(meta, { parentName });
    return option ? render(option) : undefined;
}

export const useMeta = (force?: boolean) => {
    const dispatch = useDispatch();
    const meta = useSelector((state: AppState) => state.meta.meta);
    
    if (meta === undefined || force) {
        dispatch(fetchMeta);
        return [];
    }

    return meta;
}
