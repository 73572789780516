import axios, { AxiosPromise } from 'axios';
import { setProfile } from '../reducers/auth';
import store from '../store';

export interface ApiProgress {
    id: number,
    typeId: number,
    type: string,
    percentage: number,
    score?: number,
    tries?: number,
}

export interface ApiProfile {
    id: string,
    first_name: string,
    last_name: string,
    role: string,
    progress: ApiProgress[],
    subRole?: 'Accrediteur' | 'Gebruiker',
    isProfessional: boolean,
}

const rest = {
    getProfile: async (): Promise<ApiProfile> => {
        return axios.get('auth/profile').then(({ data }: { data: ApiProfile }) => {
            store.dispatch(setProfile({
                ...data,
                subRole: localStorage.getItem('sub-role'),
            }));
            return data;
        });
    },
    login: (emailAddress: string, password?: string): AxiosPromise => {
        return axios.post('auth/login', { emailAddress, password });
    },
    logout: (): void => {
        localStorage.removeItem('accessToken');
        store.dispatch(setProfile());
    },
    register: (person: any): AxiosPromise => {
        return axios.post('auth/register', person);
    },
}

export default rest;
