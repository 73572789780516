import { faPlus, faPencil, faTrashAlt, faCheck, faPercent } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast } from '../../../RbKit';
import api, { ApiMeta } from '../../../api';
import { ApiQuiz } from '../../../api/quiz';

const QuizListView: FC = (): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ quizzes, setQuizzes ] = useState<ApiQuiz[]>([]);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listQuizzes({ query, page }).then(({ data }) => {
            setMeta(data.meta);
            setQuizzes(data.data);
            setIsLoading(false);
        });
    };
    useEffect(() => fetch(), []);

    const deleteQuiz = (quizId: number | string): void => {
        api.deleteQuiz(quizId).then(() => {
            fetch();
            toast('Quiz deleted successfully');
        });
    }

    return (<>
        <PageHeader title="Quizzes" breadcrumb={{'/quizzes': 'Overview'}}>
            <Button
                href="/quizzes/create"
                icon={faPlus}
                padding="compact"
                primary
            />
        </PageHeader>
        <Segment isLoading={isLoading}>
            <Table.Actions
                onSearch={fetch}
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Name
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing align="center">
                            Scored test
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {quizzes.length > 0 ? quizzes.map((quiz) => (
                        <Table.Row key={`row-${quiz.id}`}>
                            <Table.Cell primary name="Name">
                                <Link to={`/quizzes/${quiz.id}/edit`}>
                                    {quiz.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell
                                align="center"
                                icon={quiz.isFinal ? faCheck : undefined}
                                className="success"
                            />
                            <Table.Cell collapsing actions>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Edit"
                                        href={`/quizzes/${quiz.id}/edit`}
                                    />
                                    <Menu.Item
                                        icon={faPercent}
                                        content="Results"
                                        href={`/quizzes/${quiz.id}/progress`}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Are you sure you wish to delete this quiz? All linked results will also be deleted. This action cannot be undone"
                                        onConfirm={() => deleteQuiz(quiz.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Delete"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default QuizListView;
