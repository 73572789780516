import { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, request } from '.';
import { ApiImage } from './image';

export interface ApiMeta {
    id: number,
    children?: Partial<ApiMeta>[],
    parentId?: number,
    parent?: ApiMeta,
    name: string,
    description?: string,
    color?: string,
    imageId?: number,
    image?: ApiImage,
    showInFilter: boolean,
}

export const emptyMeta: Partial<ApiMeta> = {
    children: undefined,
    parentId: undefined,
    parent: undefined,
    name: '',
    description: undefined,
    color: undefined,
    imageId: undefined,
    image: undefined,
    showInFilter: false,
}

const rest = {
    autoComplete: (query: string): AxiosPromise<ApiPaginatedResponse<ApiMeta[]>> => {
        return request.get(`admin/meta/autocomplete?query=${query}`);
    },
    delete: (metaId: number | string): AxiosPromise => {
        return request.delete(`admin/meta/${metaId}`);
    },
    get: (metaId: number): AxiosPromise<ApiMeta> => {
        return request.get(`admin/meta/${metaId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiMeta[]>> => {
        return request.get(`admin/meta?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (meta: Partial<ApiMeta>): AxiosPromise<ApiMeta> => {
        return request.post(`admin/meta${meta.id ? `/${meta.id}` : ''}`, meta);
    },
}

export default rest;
