import { faImage, faSearchPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { getValue } from '../../../../lib/block';
import { BlockProps, Segment } from '../../../../RbKit';
import ImageThumb from '../../../../components/ImageThumb';
import { fitImage } from '../../../../lib/image';
import styles from './styles.module.scss';
import { setImages } from '../../../../reducers/lightbox';

const Block: FC<BlockProps> = ({ data, front }): JSX.Element | null => {
    const image = getValue(data, 'image');
    const mobile = getValue(data, 'mobileImage');
    const dispatch = useDispatch();

    if (front) {
        if (!image || !image.src) return null;
        const caption = getValue(data, 'caption');
        const zoom = getValue(data, 'zoom');
        const link = getValue(data, 'link');
        const fullImage = fitImage(image.src, 1440);
        let width = getValue(data, 'width');
        if (!width || width === '') {
            width = '100%';
        } else if (!width.includes('%') && !width.includes('px')) {
            width = `${width}%`;
        }

        let Elem = 'div';
        let elemProps: any = {};

        if (zoom === true) {
            Elem = 'a';
            elemProps.href = image.src;
            elemProps.onClick = (e: any) => {
                e.preventDefault();
                dispatch(setImages([image.src]));
            }
        } else if (link && link !== '') {
            Elem = 'a';
            elemProps.href = link;
        }

        return (
            <Elem
                className={`${styles.image} ${zoom === true ? styles.hasZoom : ''} block-image`}
                style={{ justifyContent: getValue(data, 'align') || 'flex-start' }}
                data-aos="zoom-in-up"
                {...elemProps}
            >
                <picture style={{ width }}>
                    {mobile && (<>
                        <source media="(max-width: 767px)" srcSet={fitImage(mobile.src, 846)} />
                        <source media="(min-width: 768px)" srcSet={fullImage} />
                    </>)}
                    <img src={fullImage} alt={getValue(data, 'alt')} style={{ width }} />
                </picture>
                {caption && caption !== '' && <em className={styles.imageCaption}>{caption}</em>}
                {zoom === true && (
                    <div className={styles.imageZoom}>
                        <FontAwesomeIcon icon={faSearchPlus} />
                    </div>
                )}
            </Elem>
        )
    }

    return (
        <Segment>
            <ImageThumb
                square
                detail
                image={getValue(data, 'image')}
            />
            {mobile && (
                <ImageThumb
                    detail
                    square
                    image={mobile}
                />
            )}
        </Segment>
    );
}

const data = {
    Block,
    icon: faImage,
    getData: () => ({
        block: 'image',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'image',
            type: 'image',
            label: 'Image',
            value: '',
        }, {
            id: 'mobileImage',
            type: 'image',
            label: 'Mobile image',
            value: '',
        }, {
            id: 'zoom',
            type: 'checkbox',
            label: 'Enable zoom',
            value: false,
        }, {
            id: 'align',
            type: 'dropdown',
            label: 'Alignment',
            value: 'flex-start',
            props: {
                options: [{
                    text: 'Left',
                    value: 'flex-start',
                }, {
                    text: 'Center',
                    value: 'center',
                }, {
                    text: 'Right',
                    value: 'flex-end',
                }],
            }
        }, {
            id: 'alt',
            type: 'text',
            label: 'Alt text',
            value: '',
        }, {
            id: 'caption',
            type: 'text',
            label: 'Caption',
            value: '',
        }, {
            id: 'link',
            type: 'text',
            label: 'Link',
            value: '',
        }, {
            id: 'width',
            type: 'text',
            label: 'Image width (px or %), empty for default width',
            value: '',
        }],
    })
};

export default data;
