import { find } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { request } from '../../../../api';
import { Segment, Button } from '../../../../RbKit';
import SortableList, { reorder } from '../../../../components/SortableList';

interface SortModeProps {
    items: any[],
    onCancel: () => void,
    onUpdate: (reordered?: any[]) => void,
}

const SortMode: FC<SortModeProps> = ({ items, onCancel, onUpdate }): JSX.Element => {
    const [ sorted, setSorted ] = useState<any[]>(items);
    useEffect(() => setSorted(items), [items]);

    const onDragEnd = (result: any): void => {
        if (result.destination) {
            setSorted(reorder(sorted, result.source.index, result.destination.index));
        }
    }

    const handleSave = (): void => {
        request.post('lessons/sort', { lessons: sorted.map(o => o.id) }).then(() => {
            onUpdate(sorted);
        });
    }

    return (<>
        <div style={{ marginBottom: '1rem' }}>
            <Button
                label="Opslaan"
                onClick={() => handleSave()}
                primary
            />
            <Button
                label="Annuleren"
                onClick={onCancel}
            />
        </div>
        <SortableList
            items={sorted}
            onUpdate={onDragEnd}
            renderListItem={(item: any) => {
                let indent = 0;
                const parent = find(items, { id: item.parentId });
                
                if (parent) {
                    indent = parent.parentId ? 2 : 1;
                }

                return (
                    <Segment padding="dense" style={{ marginLeft: 24 * indent }}>
                        {item.name}
                    </Segment>
                );
            }}
        />
    </>);
}

export default SortMode;
