import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from './';

export interface ApiQuizQuestionAnswer {
    id: number | string,
    content: string,
    explanation?: string,
    isCorrect: boolean,
    position: number,
}

export interface ApiQuizQuestion {
    id: number,
    name: string,
    content?: any[],
    position: number,
    answers?: ApiQuizQuestionAnswer[],
    multiple: boolean,
}

export interface ApiQuiz {
    id: number,
    name: string,
    intro?: any[],
    passed?: any[],
    failed?: any[],
    maxQuestions: number,
    isFinal: boolean,
    minScore: number,
    maxTries: number,
    questions?: ApiQuizQuestion[],
    showIntro: boolean,
    startBtnLabel?: string,

    passedMailSubject?: string,
    passedMailContent?: string,
    failedMailSubject?: string,
    failedMailContent?: string,
}

const rest = {
    delete: (quizId: number | string): AxiosPromise => {
        return axios.delete(`quizzes/${quizId}`);
    },
    deleteQuestion: (quizId: number | string, quizQuestionId: number): AxiosPromise => {
        return axios.delete(`quizzes/${quizId}/questions/${quizQuestionId}`);
    },
    get: (quizId: number): AxiosPromise<ApiQuiz> => {
        return axios.get(`quizzes/${quizId}`);
    },
    getFront: (quizId: number): AxiosPromise<ApiQuiz> => {
        return axios.get(`front/quizzes/${quizId}`);
    },
    getQuestion: (quizId: number, quizQuestionId: number): AxiosPromise<ApiQuizQuestion> => {
        return axios.get(`quizzes/${quizId}/questions/${quizQuestionId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiQuiz[]>> => {
        return axios.get(`quizzes?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    listQuestions: (
        quizId: number,
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiQuizQuestion[]>> => {
        return axios.get(`quizzes/${quizId}/questions?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (quiz: Partial<ApiQuiz>): AxiosPromise<ApiQuiz> => {
        return axios.post(`quizzes${quiz.id ? `/${quiz.id}` : ''}`, quiz);
    },
    putQuestion: (quizId: number, quizQuestion: Partial<ApiQuizQuestion>): AxiosPromise<ApiQuizQuestion> => {
        return axios.post(`quizzes/${quizId}/questions${quizQuestion.id ? `/${quizQuestion.id}` : ''}`, quizQuestion);
    },
}

export default rest;
