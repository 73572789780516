import { createAction, handleActions } from 'redux-actions';

export interface LightboxState {
    images: string[],
}

export const addImage = createAction('addImage');
export const setImages = createAction('setImages');

const actions = handleActions({
    [addImage.toString()]: (state, action: any) => ({
        ...state,
        images: [ ...state.images, action.payload ],
    }),
    [setImages.toString()]: (state, action: any) => ({
        ...state,
        images: action.payload,
    }),
}, {
    images: [],
});

export default actions;
