import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import api from '../../../api';
import { ApiModule } from '../../../api/module';
import ModuleBlock from '../../../components/front/ModuleBlock';
import Section from '../../../components/front/Section';
import { setCurrentModule } from '../../../reducers/progress';
import styles from './styles.module.scss';

const ModuleView: FC = (): JSX.Element => {
    const [ modules, setModules ] = useState<ApiModule[]>([]);
    const dispatch = useDispatch();

    const fetch = useCallback((): void => {
        dispatch(setCurrentModule());

        api.front.listModules({}, 'metaOptions.parent').then(({ data }) => {
            setModules(data.data);
        });
    }, [dispatch]);

    useEffect(fetch, [fetch]);

    return (
        <Section alt large>
            <div>
                <div className={styles.moduleList}>
                    {modules.map((mod) => (
                        <div key={`mod-${mod.id}`}>
                            <ModuleBlock rmodule={mod} />
                        </div>
                    ))}
                </div>
            </div>
        </Section>
    );
}

export default ModuleView;
