import { toast } from '../RbKit';

export const copyStr = (str: string, msg?: string) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (msg) toast(msg);
}

export const slugify = (str: string): string => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:; &";
    var to   = "aaaaeeeeiiiioooouuuunc--------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export const secToMin = (seconds: number): string => {
    let min = Math.floor(seconds / 60);
    const sec = Math.round(((seconds / 60) - min) * 60);
    const hrs = Math.floor(min / 60);
    min -= hrs * 60;

    let time = `${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}`;

    if (hrs <= 0) {
        return time;
    }

    time = `${hrs}:${time}`;
    return time;
}
