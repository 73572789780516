import { FC } from 'react';
import { Segment, Table } from '../../../RbKit';
import { copyStr } from '../../../lib/util';

export const placeholders = [
    'Volledige naam',
    'Voornaam',
    'Tussenvoegsel',
    'Achternaam',
    'E-mailadres',
    'Link',
    'Module',
];

const Placeholders: FC = () => {
    return (
        <Segment header="Placeholders">
            <Table fluid>
                <tbody>
                    {placeholders.map((p, i) => (
                        <Table.Row key={`pl-${i}`}>
                            <Table.Cell
                                onClick={() => {
                                    if (p.toLocaleLowerCase().includes('link')) {
                                        copyStr(`[Link tekst](@@${p})`, 'Copied placeholder');
                                    } else {
                                        copyStr(p, 'Copied placeholder');
                                    }
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                @@{p}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </tbody>
            </Table>
        </Segment>
    );
}

export default Placeholders;
