import { faRectangleWide } from '@fortawesome/pro-light-svg-icons';
import { findIndex } from 'lodash';
import React, { FC } from 'react';
import ContentEditor from '../..';
import Section from '../../../../components/front/Section';
import { getValue } from '../../../../lib/block';
import { useEditorSettings } from '../../../../lib/editor';
import { BlockProps } from '../../../../RbKit';
import Content from '../../../front/Content';

const Block: FC<BlockProps> = ({ data, onBlockChange, front, lessonDone, settings }): JSX.Element => {
    const allSettings = useEditorSettings();

    const handleUpdate = (items: any[]): void => {
        const newData = { ...data };
        const index = findIndex(newData.fields, { id: 'section' }) as number;
        newData.fields[index].value = items;
        if (onBlockChange) {
            onBlockChange(newData);
        }
    }

    if (front) {
        const type = getValue(data, 'type');

        return (
            <Section alt={type === 'alt' || settings.type === 'alt'} large={settings.size === 'large'}>
                <Content
                    blocks={getValue(data, 'section')}
                    settings={settings}
                    lessonDone={lessonDone}
                />
            </Section>
        );
    }

    return (
        <ContentEditor
            content={{
                blocks: getValue(data, 'section'),
                settings: allSettings,
            }}
            onUpdate={(items) => handleUpdate(items)}
        />
    );
}

const data = {
    Block,
    icon: faRectangleWide,
    settings: [{
        id: 'type',
        type: 'dropdown',
        label: 'Type',
        value: 'white',
        props: {
            options: [{
                text: 'White',
                value: 'white',
            }, {
                text: 'Alt (grey)',
                value: 'alt',
            }],
        }
    }, {
        id: 'size',
        type: 'dropdown',
        label: 'Size',
        value: 'small',
        props: {
            options: [{
                text: 'Small',
                value: 'small',
            }, {
                text: 'Large',
                value: 'large',
            }],
        }
    }],
    getData: () => ({
        block: 'section',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'section',
            type: 'contenteditor',
            value: [],
        }],
    })
};

export default data;
