import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { openAuthModal } from '../../../components/front/AuthModal';
import { useProfile } from '../../../lib/auth';
import styles from './styles.module.scss';

export const toggleNavigation = (state: boolean): void => {
    dispatchEvent(new CustomEvent('toggle-navigation', { detail: { state }}));
}

const Navigation: FC = (): JSX.Element => {
    const [ open, setOpen ] = useState<boolean>(false);
    const location = useLocation();
    const profile = useProfile();

    useEffect(() => {
        setOpen(false);
    }, [location]);

    useEffect(() => {
        const handleOpen = ({ detail }: any): void => {
            setOpen(detail.state);
        }

        window.addEventListener('toggle-navigation', handleOpen, true);
        return () => window.removeEventListener('toggle-navigation', handleOpen, true);
    }, []);

    const login = (e: any): void => {
        e.stopPropagation();
        e.preventDefault();
        openAuthModal(window.location.href);
        setOpen(false);
    }

    return (<>
        {open && (
            <div
                className={styles.shadow}
                onClick={() => setOpen(false)}
            />
        )}
        <div className={`${styles.container} ${open ? styles.open : ''}`}>
            <div className={styles.close}>
                <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => setOpen(false)}
                />
            </div>
            <div className={styles.navigation}>
                <Link to="/">
                    Home
                </Link>
                <Link to="/modules">
                    Lesmodules
                </Link>
                {profile ? (
                    <Link
                        to="/account"
                    >
                        Mijn account
                    </Link>
                ) : (
                    <Link
                        onClick={login}
                        to="/account"
                    >
                        Inloggen
                    </Link>
                )}
            </div>
        </div>
    </>);
}

export default Navigation;
