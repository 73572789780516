import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
    block?: boolean,
    disabled?: boolean,
    href?: string,
    icon?: IconDefinition,
    label?: string,
    link?: boolean,
    percentage?: number,
    primary?: boolean,
    target?: string,
    trigger?: boolean,
    triggered?: boolean,
    type?: string,
}

const Button: FC<ButtonProps> = ({
    children,
    className,
    disabled,
    href,
    icon,
    label,
    link,
    onClick,
    percentage,
    primary,
    target,
    type,
    ...props
}) => {
    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (
            disabled ||
            (percentage !== undefined && percentage < 100)
        ) {
            return undefined;
        }

        if (onClick) {
            onClick(e);
        };
    }

    const elemProps: any = {
        className: [
            styles.base,
            disabled && styles.disabled,
            link && styles.link,
            primary && styles.primary,
            percentage !== undefined && percentage < 100 ? styles.timerDisabled : '',
            className,
        ].join(' '),
        onClick: handleOnClick,
        ...props
    }

    let innerContent = icon ? (<>
        <FontAwesomeIcon icon={icon} />
        {(children || label) && (<span>{children || label}</span>)}
    </>) : (children || label);

    if (percentage !== undefined) {
        innerContent = (<>
            {innerContent}
            <span
                className={styles.timer}
                style={{ width: `${percentage}%` }}
            />
        </>)
    }

    if (href) {
        if (target) {
            elemProps.href = href;
            elemProps.target = target;
            return <a {...elemProps}>{innerContent}</a>;
        } else {
            elemProps.to = href;
            return <Link {...elemProps}>{innerContent}</Link>;
        }
    }

    return <button {...elemProps} type={type || 'button'}>{innerContent}</button>
}

export default Button;
