import { faCheck } from '@fortawesome/pro-light-svg-icons';
import React, { FC } from 'react';
import parse from 'html-react-parser';
import { getValue } from '../../../../lib/block';
import { BlockProps, Segment } from '../../../../RbKit';
import styles from '../Text/styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Block: FC<BlockProps> = ({ data }): JSX.Element => {
    return (
        <Segment className={styles.container} padding="dense">
            <div>
                {getValue(data, 'correct') && (
                    <FontAwesomeIcon
                        icon={faCheck}
                        className="success"
                        style={{ marginRight: '1rem' }}
                    />
                )}
                {parse(getValue(data, 'answer') || '')}
            </div>
        </Segment>
    );
}

const data = {
    Block,
    icon: faCheck,
    getData: () => ({
        block: 'answer',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'answer',
            type: 'text',
            label: 'Answer',
            value: '',
        }, {
            id: 'correct',
            type: 'checkbox',
            label: 'Correct answer',
            value: false,
        }],
    })
};

export default data;
