import { Dispatch } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import api from '../api';
import { ApiLesson } from '../api/lesson';
import { ApiModule } from '../api/module';

export interface ProgressState {
    currentLesson?: ApiLesson,
    currentModule?: ApiModule,
    isDeeplink: boolean,
    isFetching: {
        lesson: boolean,
        rmodule: boolean,
    },
}

export const setIsFetching = createAction('setIsFetching');
export const setCurrentLesson = createAction('setCurrentLesson');
export const setCurrentModule = createAction('setCurrentModule');
export const setDeeplink = createAction('setDeeplink');

export async function fetchCurrentLesson(dispatch: Dispatch, getState: any, lessonId: number) {
    if (getState().progress.isFetching.lesson) {
        return;
    }
    dispatch(setIsFetching({ lesson: true }));
    const res = await api.front.getLesson(lessonId);
    dispatch(setCurrentLesson(res.data));
    dispatch(setIsFetching({ lesson: false }));
}

export async function fetchCurrentModule(dispatch: Dispatch, getState: any, moduleId: number) {
    if (getState().progress.isFetching.rmodule) {
        return;
    }
    dispatch(setIsFetching({ rmodule: true }));
    const res = await api.front.getModule(moduleId, 'lessons,metaOptions.parent');
    dispatch(setCurrentModule(res.data));
    dispatch(setIsFetching({ rmodule: false }));
    dispatch(setDeeplink(window.location.search.includes('deeplink=true')));
}

const actions = handleActions({
    [setCurrentLesson.toString()]: (state, action: { payload: any }) => ({
        ...state,
        currentLesson: action.payload,
    }),
    [setCurrentModule.toString()]: (state, action: { payload: any }) => ({
        ...state,
        currentModule: action.payload,
    }),
    [setDeeplink.toString()]: (state, action: { payload: any }) => ({
        ...state,
        isDeeplink: action.payload,
    }),
    [setIsFetching.toString()]: (state, action: any) => ({
        ...state,
        isFetching: {
            ...state.isFetching,
            ...action.payload
        },
    }),
}, {
    currentLesson: undefined,
    currentModule: undefined,
    isDeeplink: false,
    isFetching: {
        lesson: false,
        rmodule: false,
    },
});

export default actions;
