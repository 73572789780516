import React, { FC, useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store';
import styles from './styles.module.scss';

const LightboxModal: FC = (): JSX.Element | null => {
    const [ images, setImages ] = useState<string[]>([]);
    const [ index, setIndex ] = useState<number>(0);
    const _images = useSelector((state: AppState) => state.lightbox.images);

    useEffect(() => {
        setImages(_images);
    }, [_images]);

    return images.length > 0 ? (
        <Lightbox
            onCloseRequest={() => {
                setImages([]);
                setIndex(0);
            }}
            mainSrc={images[index]}
            nextSrc={images[(index + 1) % images.length]}
            prevSrc={images[(index + images.length - 1) % images.length]}
            onMovePrevRequest={() => setIndex((index + images.length - 1) % images.length)}
            onMoveNextRequest={() => setIndex((index + 1) % images.length)}
            wrapperClassName={styles.wrapper}
        />
    ) : null;
}

export default LightboxModal;
