import { createAction, handleActions } from 'redux-actions';

export interface ContentEditorState {
    settings: { [key: string]: any },
}

export const setSettings = createAction('setSettings');

const actions = handleActions({
    [setSettings.toString()]: (state, action: any) => ({
        ...state,
        settings: action.payload,
    }),
}, {
    settings: {},
});

export default actions;
