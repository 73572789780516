import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import React, { FC, useState } from 'react';
import { useEffect } from 'react';
import api from '../../../../api';
import { ApiModule } from '../../../../api/module';
import ModuleBlock from '../../../../components/front/ModuleBlock';
import { getValue } from '../../../../lib/block';
import { BlockProps, DropdownOption } from '../../../../RbKit';
import styles from './styles.module.scss';

const Block: FC<BlockProps> = ({ data, front }): JSX.Element => {
    const [ modules, setModules ] = useState<ApiModule[]>([]);
    
    const fetch = (ids: number[]): void => {
        api.front.listModules({ limit: 3, ids }, 'metaOptions.parent').then((res) => {
            setModules(res.data.data);
        });
    }
    useEffect(() => {
        fetch(getValue(data, 'modules') || []);
    }, [data]);

    return (
        <div className={styles.moduleList}>
            {modules.map((mod) => (
                <div key={`mod-${mod.id}`}>
                    <ModuleBlock rmodule={mod} />
                </div>
            ))}
        </div>
    );
}

const data = {
    Block,
    icon: faEdit,
    loadOptions: (id: string, callback: (options: DropdownOption[]) => void) => {
        if (id !== 'modules') return;
        api.listModules({ limit: 999 }).then(({ data }) => {
            callback(data.data.map((o) => ({
                text: o.name,
                value: o.id,
            })));
        });
    },
    getData: () => ({
        block: 'modules',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'modules',
            type: 'dropdown',
            label: 'module',
            props: { multiple: true },
            value: [],
        }],
    }),
};

export default data;
