import { faCopy, faTrashAlt, faUnlink } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../api';
import { ApiImage } from '../../api/image';
import { copyStr } from '../../lib/util';
import { Button, Confirm, Form, Grid, Modal, Table, toast } from '../../RbKit';
import { UploadButton } from '../../views/admin/ImageView';
import styles from './styles.module.scss';

export const openImageModal = (id: number): void => {
    if (!id) return;
    dispatchEvent(new CustomEvent('open-image-modal', { detail: { id } }));
}

const ImageModal: FC = (): JSX.Element => {
    const [ open, setOpen ] = useState<boolean>(false);
    const [ image, setImage ] = useState<ApiImage>();
    const [ changes, setChanges ] = useState<boolean>(false);

    useEffect(() => {
        const handleOpen = ({ detail }: any): void => {
            api.getImage(detail.id, { linked: 1 }).then(({ data }) => {
                setImage(data);
                setChanges(false);
                setOpen(true);
            });
        }

        window.addEventListener('open-image-modal', handleOpen, true);
        return () => window.removeEventListener('open-image-modal', handleOpen, true);
    }, []);

    useEffect(() => {
        if (!open) {
            setImage(undefined);
            setChanges(false);
        }
    }, [open]);

    const save = (): void => {
        if (!changes || !image) return;
        api.putImage(image).then(() => {
            toast('Image saved');
            setChanges(false);
        });
    }

    const unlink = (imageId: number, obj: any): void => {
        api.unlinkImage(imageId, obj).then(() => {
            toast(`Image unlink from ${obj.type}`);
            api.getImage(imageId, { linked: 1 }).then(({ data }) => {
                setImage(data);
            });
        });
    }

    const handleDelete = (imageId: number): void => {
        api.deleteImage(imageId).then(() => {
            toast('Image deleted succesfully');
            setOpen(false);
            dispatchEvent(new CustomEvent('image-deleted'));
        });
    }

    const replaceFile = (file: any): void => {
        if (!image) return;
        api.replaceImage(image.id, file).then(() => {
            api.getImage(image.id, { linked: 1 }).then(({ data }) => setImage(data));
            dispatchEvent(new CustomEvent('image-replaced'));
        });
    }

    return (
        <Modal
            header="Afbeelding"
            size="large"
            open={open}
            onClose={() => setOpen(false)}
            fitOnScreen
        >
            {image && (<>
                <Grid.Row>
                    <Grid.Column md={8}>
                        <div className={`${styles.image} ${image.width < image.height && styles.portrait}`}>
                            <img src={image.src} alt="" />
                        </div>
                    </Grid.Column>
                    <Grid.Column md={4}>
                        <div className={styles.info}>
                            <h2>Information</h2>
                            <Table small style={{ marginBottom: '2rem', backgroundColor: '#ffffff' }}>
                                <tbody>
                                    <Table.Row>
                                        <Table.Cell collapsing>
                                            ID
                                        </Table.Cell>
                                        <Table.Cell>
                                            : {image.id}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell collapsing>
                                            Dimenions
                                        </Table.Cell>
                                        <Table.Cell>
                                            : {image.width}x{image.height}
                                        </Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell collapsing>
                                            Type
                                        </Table.Cell>
                                        <Table.Cell>
                                            : {image.extension}
                                        </Table.Cell>
                                    </Table.Row>
                                </tbody>
                            </Table>

                            <div className={styles.copy}>
                                <Form.Input
                                    readOnly
                                    label="Permalink"
                                    value={image.src}
                                />
                                <Button
                                    primary
                                    onClick={() => copyStr(image.src, 'Copied')}
                                    icon={faCopy}
                                />
                            </div>

                            <Form.Input
                                label="Alternative name"
                                onChange={({ value }: any) => {
                                    setImage({ ...image, name: value });
                                    setChanges(true);
                                }}
                                onBlur={() => save()}
                                value={image.name}
                            />

                            <div className={styles.buttons}>
                                <UploadButton
                                    label="Replace"
                                    onUpload={(e: any) => replaceFile(e.target.files[0])}
                                />
                                <Confirm
                                    content="Are you sure you want to delete this image? The image will automatically be unlinked everywhere, which most likely leave empty spaces."
                                    trigger={<Button style={{ marginLeft: '.5rem' }} icon={faTrashAlt} />}
                                    onConfirm={() => handleDelete(image.id)}
                                />
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>

                {image.linkedContent && (<>
                    <h2>Linked to</h2>
                    <Table>
                        <thead>
                            <Table.Row>
                                <Table.HeaderCell collapsing>
                                    Type
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Item
                                </Table.HeaderCell>
                                <Table.HeaderCell collapsing />
                            </Table.Row>
                        </thead>
                        <tbody>
                            {['pages', 'modules', 'lessons', 'chapters'].map((type: any) => {
                                if (image.linkedContent && image.linkedContent[type]) {
                                    return image.linkedContent[type].map((o) => (
                                        <Table.Row key={`${type}-${o.id}`}>
                                            <Table.Cell collapsing>
                                                {o.type}
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Link
                                                    to={o.type !== 'lesson'
                                                        ? `/${type}/${o.id}/edit`
                                                        : `/modules/${o.moduleId}/${type}/${o.id}/edit`}
                                                    onClick={() => setOpen(false)}
                                                >
                                                    {o.name}
                                                </Link>
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                <Confirm
                                                    content="Are you sure you wish to unlink this image from this item? This action cannot be undone and will most likely leave an empty space."
                                                    onConfirm={() => unlink(image.id, o)}
                                                    trigger={<Button
                                                        trigger
                                                        icon={faUnlink}
                                                    />}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ));
                                }

                                return null;
                            })}
                        </tbody>
                    </Table>
                </>)}
            </>)}
        </Modal>
    );
}

export default ImageModal;
