import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import React, { FC, useCallback, useState } from 'react';
import { useEffect } from 'react';
import api from '../../../../api';
import { ApiQuiz } from '../../../../api/quiz';
import { getValue } from '../../../../lib/block';
import { BlockProps, DropdownOption, Segment } from '../../../../RbKit';
import Quiz from './Quiz';

const Block: FC<BlockProps> = ({ data, front }): JSX.Element => {
    const [ quiz, setQuiz ] = useState<ApiQuiz>();

    const fetch = useCallback((id?: number): void => {
        if (!id) return;
        if (!quiz || id !== quiz.id) {
            api.front.getQuiz(id).then(({ data }) => {
                setQuiz(data);
            });
        }
    }, [quiz]);

    useEffect(() => {
        fetch(getValue(data, 'quiz'));
    }, [data, fetch]);

    return front ? <Quiz quiz={quiz} /> : (
        <Segment>
            <b>{quiz?.name || 'No quiz selected'}</b>: {quiz?.questions?.length} question{quiz?.questions?.length === 1 ? '' : 's'}
        </Segment>
    );
}

const data = {
    Block,
    icon: faQuestionCircle,
    loadOptions: (id: string, callback: (options: DropdownOption[]) => void) => {
        if (id !== 'quiz') return;
        api.listQuizzes({ limit: 999 }).then(({ data }) => {
            callback(data.data.map((o) => ({
                text: o.name,
                value: o.id,
            })));
        });
    },
    getData: () => ({
        block: 'quiz',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'quiz',
            type: 'dropdown',
            label: 'Quiz',
            value: '',
        }],
    }),
};

export default data;
