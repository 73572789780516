import  { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, request } from '.';

type OrientationType = 'landscape' | 'portrait';

export interface ApiImageFolder {
    id: number,
    parentId?: number,
    name: string,
    breadcrumb?: ApiImageFolder[],
}

interface ApiSimpleItem {
    id: number,
    moduleId: number,
    name: string,
    type: string,
}

export interface ApiImage {
    id: number,
    extension: string,
    height: number,
    name: string,
    orientation: OrientationType,
    src: string,
    width: number,
    linkedContent?: {
        [key: string]: ApiSimpleItem[],
    }
}

const rest = {
    delete: (imageId: number | string): AxiosPromise => {
        return request.delete(`images/${imageId}`);
    },
    get: (imageId: number, query?: { [key: string]: any }): AxiosPromise<ApiImage> => {
        return request.get(`images/${imageId}?${qs.stringify(query)}`);
    },
    list: (query?: { [key: string]: any }): AxiosPromise<ApiPaginatedResponse<ApiImage[]>> => {
        return request.get(`images?${qs.stringify(query)}`);
    },
    listFolders: (folderId?: number): AxiosPromise<ApiImageFolder[]> => {
        return request.get(`images/folders?folderId=${folderId || ''}`);
    },
    putFolder: (folder: Partial<ApiImageFolder>): AxiosPromise<ApiImageFolder> => {
        return request.post(`images/folders${folder.id ? `/${folder.id}` : ''}`, folder);
    },
    removeFolder: (folderId: number): AxiosPromise<any> => {
        return request.delete(`images/folders/${folderId}`);
    },
    put: (image: ApiImage): AxiosPromise<ApiImage> => {
        return request.post(`images/${image.id}`, image);
    },
    unlink: (imageId: number, obj: any): AxiosPromise<ApiImage> => {
        return request.post(`images/${imageId}/unlink`, obj);
    },
    upload: (
        file: any,
        folderId?: number,
        onUploadProgress?: (progressEvent: any) => void,
        forObject?: string,
        forObjectId?: number,
    ): AxiosPromise => {
        const fd = new FormData();
        fd.append('file', file);
        if (folderId) fd.append('folderId', `${folderId}`);
        if (forObject) fd.append('forObject', forObject);
        if (forObjectId) fd.append('forObject_id', `${forObjectId}`);

        return request.post('images', fd, {
            onUploadProgress,
        });
    },
    replace: (
        imageId: number,
        file: any,
        onUploadProgress?: (progressEvent: any) => void,
    ): AxiosPromise => {
        const fd = new FormData();
        fd.append('file', file);

        return request.post(`images/${imageId}/replace`, fd, {
            onUploadProgress,
        });
    }
}

export default rest;
