import { faColumns } from '@fortawesome/pro-light-svg-icons';
import { findIndex } from 'lodash';
import React, { FC } from 'react';
import ContentEditor from '../..';
import { getValue } from '../../../../lib/block';
import { BlockProps, Button } from '../../../../RbKit';
import Content from '../../../front/Content';
import styles from './styles.module.scss';

const Block: FC<BlockProps> = ({ data, onBlockChange, front, lessonDone }): JSX.Element => {
    const handleUpdate = (items: any[], id: string): void => {
        const newData = { ...data };
        const index = findIndex(newData.fields, { id }) as number;
        newData.fields[index].value = items;
        if (onBlockChange) {
            onBlockChange(newData);
        }
    }

    const addColumn = (): void => {
        const newData = { ...data };
        
        newData.fields.push({
            id: `column-${Math.random().toString(36).substring(2,7)}`,
            label: '',
            type: 'contenteditor',
            value: [],
        });

        if (onBlockChange) {
            onBlockChange(newData);
        }
    }

    const removeColumn = (id: string): void => {
        const newData = { ...data };
        const index = findIndex(newData.fields, { id }) as number;

        if (onBlockChange) {
            onBlockChange({
                ...newData,
                fields: newData.fields.filter((o: any, i: number) => i !== index),
            });
        }
    }

    if (front) {
        return (
            <div className={styles.container}>
                {data.fields.map((c)=> (
                    <div
                        key={c.id}
                        className={styles.column}
                    >
                        <div className={styles.innerColumn}>
                            <Content
                                blocks={getValue(data, c.id)}
                                settings={{}}
                                lessonDone={lessonDone}
                            />
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return (<>
        <Button
            label="Add column"
            onClick={addColumn}
        />
        <div style={{ display: 'flex' }}>
            {data.fields.map((c)=> (
                <div key={c.id} style={{ flex: 1, margin: '1rem' }}>
                    <ContentEditor
                        content={{ blocks: getValue(data, c.id) } }
                        onUpdate={(items) => handleUpdate(items, c.id)}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            label="Remove column"
                            onClick={() => removeColumn(c.id)}
                            style={{ padding: '2px .5rem', fontSize: 12, marginTop: -10 }}
                        />
                    </div>
                </div>
            ))}
        </div>
    </>);
}

const data = {
    Block,
    icon: faColumns,
    getData: () => ({
        block: 'columns',
        sidebar: false,
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'column-0',
            type: 'contenteditor',
            value: [],
        }, {
            id: 'column-1',
            type: 'contenteditor',
            value: [],
        }],
    })
};

export default data;
