import { findIndex } from 'lodash';
import { faIndent } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createRef, FC, RefObject, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import ContentEditor from '../..';
import { getValue } from '../../../../lib/block';
import { BlockProps, Segment } from '../../../../RbKit';
import Content from '../../../front/Content';
import textStyles from '../Text/styles.module.scss';
import styles from './styles.module.scss';

const Block: FC<BlockProps> = ({ data, front, onBlockChange }): JSX.Element => {
    const Container = front ? 'div' : Segment; 
    const containerRef: RefObject<HTMLDivElement> = createRef();
    const [ isCollapsed, setIsCollapsed ] = useState<boolean>(true);

    useEffect(() => {
        if (front || !containerRef.current) return;
        containerRef.current.style.setProperty('--max-height', containerRef.current.scrollHeight + 'px');
    }, [containerRef, data, front]);

    const handleUpdate = (items: any[]): void => {
        const newData = { ...data };
        const index = findIndex(newData.fields, { id: 'content' }) as number;
        newData.fields[index].value = items;
        if (onBlockChange) {
            onBlockChange(newData);
        }
    }

    const handleToggle = (force?: boolean): void => {
        if (!containerRef.current) return;
        containerRef.current.style.setProperty('--max-height', containerRef.current.scrollHeight + 'px');
        setIsCollapsed(force !== undefined ? force : !isCollapsed);
    }

    return (
        <Container className={`${textStyles.container} ${front ? '' : textStyles.cms} ${styles.item}  ${isCollapsed ? styles.collapsed : ''}`}>
            <div className={styles.title} onClick={() => handleToggle()}>
                {parse(getValue(data, 'title') || '')}
                <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronUp} />
            </div>
            <div className={styles.content} ref={containerRef}>
                {front ? (
                    <Content blocks={getValue(data, 'content')} settings={{}} />
                ) : (
                    <ContentEditor
                        content={{ blocks: getValue(data, 'content') } }
                        onUpdate={(items) => handleUpdate(items)}
                    />
                )}
            </div>
        </Container>
    );
}

const data = {
    Block,
    icon: faIndent,
    getData: () => ({
        block: 'accordion',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'title',
            type: 'text',
            label: 'Title',
            value: '',
        }, {
            id: 'content',
            type: 'contenteditor',
            value: [],
        }],
    })
};

export default data;
