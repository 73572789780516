import MDEditor from '@uiw/react-md-editor';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import api from '../../../api';
import { ApiTemplate } from '../../../api/template';
import { Button, Form, Grid, PageHeader, Segment, toast } from '../../../RbKit';
import Placeholders from './Placeholders';

interface TemplateEditProps extends RouteComponentProps<{ id?: string }> {
}

const TemplateEditView: FC<TemplateEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ template, setTemplate ] = useState<Partial<ApiTemplate>>({});

    const fetch = useCallback(() => {
        if (!id) return;
        setIsLoading(true);
        api.getTemplate(id).then(({ data }) => {
            setTemplate(data);
            setIsLoading(false);
        });
    }, [id]);

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setTemplate({
            ...template,
            [name]: value,
        });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        setIsLoading(true);

        api.putTemplate(template).then(({ data }) => {
            setIsLoading(false);
            toast('Template saved succesfully');
            history.push('/admin/templates');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Something went wrong', 'error');
        });
    }

    return (<>
        <PageHeader
            breadcrumb={{
                '/admin/templates': 'Templates',
                [`/admin/templates/${id ? `${id}/edit` : 'create'}`]: id ? `${template.name}` || 'New' : 'New',
            }}
            title={`${id ? 'Edit' : 'New'} template`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Grid.Row>
                <Grid.Column md={9}>
                    <Segment header="Contents" isLoading={isLoading}>
                        {(!id || template.code === 'manual') && (
                            <Form.Input
                                error={errors.name}
                                label="Name (interal use)"
                                name="name"
                                onChange={handleInput}
                                required
                                value={template.name || ''}
                            />
                        )}
                        <Form.Input
                            error={errors.subject}
                            label="Subject"
                            name="subject"
                            onChange={handleInput}
                            required
                            value={template.subject || ''}
                        />
                        <Form.Group>
                            <label>Content</label>
                            <MDEditor
                                onChange={(value: any) => handleInput({ name: 'content', value })}
                                value={template.content || ''}
                                height={500}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Button
                                label="Save"
                                primary
                                type="submit"
                            />
                            <Button
                                href="/admin/templates"
                                label="Cancel"
                                link
                            />
                        </Form.Group>
                    </Segment>
                </Grid.Column>
                <Grid.Column md={3}>
                    <Placeholders />
                </Grid.Column>
            </Grid.Row>
        </Form>
    </>);
}

export default TemplateEditView;
