import qs from 'qs';
import axios, { AxiosPromise } from 'axios';
import { ApiProfile } from './auth';
import { setProfile } from '../reducers/auth';
import store from '../store';
import { ApiUser } from './user';
import { ApiModule } from './module';
import { ApiQuizQuestionAnswer } from './quiz';

export interface ApiProgress {
    id: number,
    typeId: number,
    type: string,
    percentage: number,
    meta?: any,
    user?: ApiUser,
    module?: ApiModule,
    createdAt: string,
    updatedAt: string,
}

const rest = {
    saveQuestion: (quizId: number, questionId: number, answers: ApiQuizQuestionAnswer[] = []): AxiosPromise<{ session: string }> => {
        const session = localStorage.getItem(`quiz-session-${quizId}`);

        return axios.post(`front/progress/quiz/${quizId}`, {
            questionId,
            answers,
            session: session ? session : undefined,
        });
    },
    upsert: (type: string, typeId: number, percentage?: number, meta?: any): Promise<ApiProfile> => {
        return axios.post('front/progress', {
            percentage: percentage || 0,
            type,
            typeId,
            meta,
        }).then(({ data }: { data: ApiProfile }) => {
            store.dispatch(setProfile({
                ...data,
                subRole: localStorage.getItem('sub-role'),
            }));
            return data;
        });
    },
    retake: (type: string, typeId: number): Promise<ApiProfile> => {
        return axios.post('front/progress/retake', {
            type,
            typeId
        }).then(({ data }: { data: ApiProfile }) => {
            store.dispatch(setProfile(data));
            return data;
        });
    },
    getResults: (quizId: number, explain: boolean = false): AxiosPromise => {
        const session = localStorage.getItem(`quiz-session-${quizId}`);
        return axios.get(`front/progress/${quizId}/results?session=${session ? session : ''}${explain ? '&explain=1' : ''}`);
    },
    list: (query?: { [key: string]: any },): AxiosPromise<ApiProgress[]> => {
        return axios.get(`admin/progress?${qs.stringify(query)}`);
    },
    listQuiz: (quizId: number, query?: { [key: string]: any },): AxiosPromise<ApiProgress[]> => {
        return axios.get(`admin/progress/${quizId}?${qs.stringify(query)}`);
    },
    delete: (progressId: number): AxiosPromise => {
        return axios.delete(`progress/${progressId}`);
    }
}

export default rest;
