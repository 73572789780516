import MDEditor from '@uiw/react-md-editor';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Checkbox, Form, Grid, Menu, PageHeader, Segment, toast } from '../../../RbKit';
import api from '../../../api';
import { ApiQuiz } from '../../../api/quiz';
import ChangeHandler from '../../../components/ChangeHandler';
import QuizQuestionListView from './QuestionView';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import ContentEditor from '../../../modules/ContentEditor';
import Placeholders from '../TemplateView/Placeholders';

interface QuizEditProps extends RouteComponentProps<{ id?: string }> {
}

const QuizEditView: FC<QuizEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ quiz, setQuiz ] = useState<Partial<ApiQuiz>>({});
    const [ changesMade, setChangesMade ] = useState<boolean>(false);
    const [ tab, setTab ] = useState<'intro' | 'passed' | 'failed'>('intro');

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getQuiz(parseInt(id)).then(({ data }) => {
                setQuiz(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setQuiz({
            ...quiz,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putQuiz(quiz).then(({ data }) => {
            setChangesMade(false);
            setIsLoading(false);
            toast('Quiz saved succesfully');
            history.push(`/quizzes/${data.id}/edit`);
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Something went wrong', 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/quizzes': 'Quizzes',
                [`/quizzes/${id ? `${id}/edit` : 'create'}`]: id ? quiz.name || 'New' : 'New',
            }}
            title={`${id ? 'Edit' : 'Create'} quiz`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment isLoading={isLoading}>
                <Form.Input
                    error={errors.name}
                    label="Name"
                    name="name"
                    onChange={handleInput}
                    required
                    value={quiz.name || ''}
                />
                <Form.Input
                    error={errors.name}
                    label="Maximum no. of questions to show"
                    name="maxQuestions"
                    onChange={handleInput}
                    required
                    type="number"
                    value={quiz.maxQuestions || 0}
                />
                <Form.Group>
                    <Checkbox
                        checked={quiz.showIntro === true}
                        label="Show intro and start button"
                        onChange={({ checked }: any) => handleInput({ name: 'showIntro', value: checked })}
                    />
                </Form.Group>
                {quiz.showIntro && (
                    <Form.Input
                        label="Start button label"
                        name="startBtnLabel"
                        onChange={handleInput}
                        placeholder="Start de toets"
                        value={quiz.startBtnLabel || 'Start de toets'}
                    />
                )}
                <Form.Group>
                    <Checkbox
                        checked={quiz.isFinal}
                        label="Scored test"
                        onChange={({ checked }: any) => handleInput({ name: 'isFinal', value: checked })}
                    />
                </Form.Group>
                {quiz.isFinal && (<>
                    <Form.Input
                        label="Minimum score to pass"
                        name="minScore"
                        onChange={handleInput}
                        type="number"
                        value={quiz.minScore || 70}
                    />
                    <Form.Input
                        label="Maximum no. of tries"
                        name="maxTries"
                        onChange={handleInput}
                        type="number"
                        value={quiz.maxTries || 3}
                    />
                </>)}
            </Segment>

            <Menu tabs>
                <Menu.Item
                    active={tab === 'intro'}
                    content="Intro"
                    onClick={() => setTab('intro')}
                />
                <Menu.Item
                    active={tab === 'passed'}
                    content="Passed"
                    onClick={() => setTab('passed')}
                />
                <Menu.Item
                    active={tab === 'failed'}
                    content="Failed"
                    onClick={() => setTab('failed')}
                />
            </Menu>

            <ContentEditor
                content={{ blocks: quiz[tab] }}
                onUpdate={(content) => handleInput({ name: tab, value: content })}
            />

            {tab !== 'intro' && quiz.isFinal && (
                <Grid.Row>
                    <Grid.Column md={9}>
                        <Segment>
                            <Form.Input
                                label="Email subject"
                                name={`${tab}MailSubject`}
                                onChange={handleInput}
                                value={(quiz as any)[`${tab}MailSubject`]}
                            />
                            <Form.Group>
                                <label>Email content</label>
                                <MDEditor
                                    onChange={(value: any) => handleInput({ name: `${tab}MailContent`, value })}
                                    value={(quiz as any)[`${tab}MailContent`]}
                                    height={500}
                                />
                            </Form.Group>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column md={3}>
                        <Placeholders />
                    </Grid.Column>
                </Grid.Row>
            )}
        </Form>

        {quiz.id && (
            <div style={{ marginTop: '2rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                    <h3 style={{ margin: 0 }}>Questions</h3>
                    <Button
                        label="Add question"
                        href={`/quizzes/${quiz.id}/questions/create`}
                        icon={faPlus}
                        primary
                    />
                </div>
                <QuizQuestionListView quiz={quiz as ApiQuiz} />
            </div>
        )}
    </>);
}

export default QuizEditView;
