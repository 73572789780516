import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';
import React, { FC } from 'react';
import parse from 'html-react-parser';
import { getValue } from '../../../../lib/block';
import { BlockProps, Segment } from '../../../../RbKit';
import styles from './styles.module.scss';

const Block: FC<BlockProps> = ({ data, front }): JSX.Element => {
    return front ? (
        <div className={styles.quote}>
            <div data-aos="zoom-in-up">
                {parse(getValue(data, 'content') || '')}
            </div>
        </div>
    ) : (
        <Segment className={styles.container}>
            <div>
                {parse(getValue(data, 'content') || '')}
            </div>
        </Segment>
    );
}

const data = {
    Block,
    icon: faQuoteLeft,
    getData: () => ({
        block: 'quote',
        editorCss: '.mce-content-body { background-color: rgb(245, 245, 245) !important; }, p { font-size: 24px; font-weight: 700; }',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'content',
            type: 'wysiwyg',
            label: 'Content',
            value: '<p>Lorem ipsum dolor sit amet.</p>',
        }],
    })
};

export default data;
