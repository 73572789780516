import { faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Confirm, Menu, Segment, Table, toast } from '../../../../RbKit';
import api, { ApiMeta } from '../../../../api';
import { ApiQuiz, ApiQuizQuestion } from '../../../../api/quiz';

interface QuizQuestionQuestionListView {
    quiz: ApiQuiz,
}

const QuizQuestionListView: FC<QuizQuestionQuestionListView> = ({ quiz }): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ quizQuestions, setQuizQuestions ] = useState<ApiQuizQuestion[]>([]);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listQuizQuestions(quiz.id, { query, page }).then(({ data }) => {
            setMeta(data.meta);
            setQuizQuestions(data.data);
            setIsLoading(false);
        });
    };
    useEffect(() => fetch(), []); // eslint-disable-line

    const deleteQuizQuestion = (quizQuestionId: number): void => {
        api.deleteQuizQuestion(quiz.id, quizQuestionId).then(() => {
            fetch();
            toast('Question deleted successfully');
        });
    }

    return (<>
        <Segment isLoading={isLoading}>
            <Table.Actions
                onSearch={fetch}
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Name
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {quizQuestions.length > 0 ? quizQuestions.map((quizQuestion) => (
                        <Table.Row key={`row-${quizQuestion.id}`}>
                            <Table.Cell primary name="Name">
                                <Link to={`/quizzes/${quiz.id}/questions/${quizQuestion.id}/edit`}>
                                    {quizQuestion.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell collapsing actions>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Edit"
                                        href={`/quizzes/${quiz.id}/questions/${quizQuestion.id}/edit`}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Are you sure you wish to delete this question? All linked results will also be deleted. This action cannot be undone"
                                        onConfirm={() => deleteQuizQuestion(quizQuestion.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Delete"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default QuizQuestionListView;
