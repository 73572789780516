import { faMapPin, faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Editor } from '@tinymce/tinymce-react';
import React, { FC, RefObject, useEffect, useState } from 'react';
import { getValue } from '../../../../lib/block';
import { BlockProps, Button, Form } from '../../../../RbKit';
import styles from './styles.module.scss';

const Block: FC<BlockProps> = ({ data, onBlockChange, front }): JSX.Element => {
    const [ editPoint, setEditPoint ] = useState<number>();
    const [ right, setRight ] = useState<boolean>(false);
    const [ showPoint, setShowPoint ] = useState<number>();
    const pointsContainerRef: RefObject<HTMLDivElement> = React.createRef();
    const image = getValue(data, 'image');
    const points = getValue(data, 'points');

    useEffect(() => {
        if (showPoint === undefined || !pointsContainerRef.current) return;
        const rect = pointsContainerRef.current.getBoundingClientRect();
        setRight(((100 - points[showPoint].x) / 100) * rect.width < 432);
    }, [showPoint, points, pointsContainerRef]);

    const addPoint = (e: React.MouseEvent): void => {
        if (!pointsContainerRef.current) return;
        const newData = { ...data };

        const rect = pointsContainerRef.current.getBoundingClientRect();
        newData.fields[2].value.push({
            x: Math.round(((e.clientX - rect.left - 16) / rect.width) * 100),
            y: Math.round(((e.clientY - rect.top - 16) / rect.height) * 100),
            title: '',
            value: '',
        });
        setEditPoint(newData.fields[2].value.length - 1);

        if (onBlockChange) {
            onBlockChange(newData);
        }
    }

    const updatePoint = ({ name, value }: any): void => {
        if (editPoint === undefined) return;
        const newData = { ...data };
        points[editPoint][name] = value;
        newData.fields[2].value = points;

        if (onBlockChange) {
            onBlockChange(newData);
        }
    }

    const removePoint = (): void => {
        if (editPoint === undefined) return;
        const newData = { ...data };
        newData.fields[2].value = points.filter((o: any, i: number) => i !== editPoint);
        setEditPoint(undefined);

        if (onBlockChange) {
            onBlockChange(newData);
        }
    }

    if (front) {
        return (
            <div className={`${styles.container} ${showPoint !== undefined ? styles.ontop : ''}`}>
                <div className={styles.imageContainer}>
                    {image && (<img alt="" src={image.src} />)}
                    <div
                        className={styles.points}
                        onClick={() => setShowPoint(undefined)}
                        ref={pointsContainerRef}
                    >
                        {points.map((point: any, index: number) => (
                            <div
                                key={`point-${index}`}
                                className={`
                                    ${styles.point}
                                    ${showPoint === index ? styles.show : ''}
                                    ${right ? styles.right : ''}
                                `}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowPoint(showPoint === index ? undefined : index);
                                }}
                                style={{
                                    left: `${point.x || 0}%`,
                                    top: `${point.y || 0}%`,
                                }}
                            >
                                <div className={styles.pointIcon}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </div>
                                <div className={styles.pointContent}>
                                    <h3>{point.title}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: point.value }} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    return (<>
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                {image && (<img alt="" src={image.src} />)}
                <div
                    className={styles.points}
                    onClick={addPoint}
                    ref={pointsContainerRef}
                >
                    {points.map((point: any, index: number) => (
                        <div
                            key={`point-${index}`}
                            className={styles.point}
                            onClick={(e) => {
                                e.stopPropagation();
                                setEditPoint(index);
                            }}
                            style={{
                                left: `${point.x || 0}%`,
                                top: `${point.y || 0}%`,
                            }}
                        >
                            <div className={styles.pointIcon}>
                                <FontAwesomeIcon icon={faPlus} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {editPoint !== undefined && (
                <div className={styles.edit}>
                    <h3>Edit point</h3>
                    <Form.Input
                        label="Title"
                        name="title"
                        onChange={updatePoint}
                        value={points[editPoint].title || ''}
                    />
                    <Form.Group>
                        <label>Content</label>
                        <Editor
                            apiKey={process.env.REACT_APP_TINYMCE_KEY}
                            value={points[editPoint].value || ''}
                            init={{
                                height: 200,
                                max_height: 400,
                                menubar: false,
                                plugins: ['code', 'autoresize', 'lists', 'link', 'paste', 'xhtmlxtras', 'table'],
                                toolbar: [
                                    'code | pastetext | bold italic underline | subscript superscript | alignleft aligncenter alignright | bullist numlist | link unlink | table | formatselect | styleselect | abbr alt',
                                ],
                                autoresize_bottom_margin: 16,
                                force_br_newlines: true,
                                paste_as_text: true,
                                block_formats: 'Paragraph=p',
                                extended_valid_elements: 'span,u,i,em,strong,b',
                                relative_urls: false,
                            }}
                            onEditorChange={(c: string) => updatePoint({ name: 'value', value: c })}
                        />
                    </Form.Group>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            label="Close"
                            primary
                            onClick={() => setEditPoint(undefined)}
                        />
                        <Button
                            trigger
                            icon={faTrashAlt}
                            onClick={removePoint}
                        />
                    </div>
                </div>
            )}
        </div>
    </>);
}

const data = {
    Block,
    icon: faMapPin,
    getData: () => ({
        block: 'pinpoint',
        sidebar: true,
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'image',
            type: 'image',
            label: 'Image',
            value: '',
        }, {
            id: 'points',
            type: 'points',
            value: [],
        }],
    })
};

export default data;
