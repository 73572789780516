import React, { FC, useState } from 'react';
import api from '../../../api';
import { useProfile } from '../../../lib/auth';
import { Button, Form, Message } from '../../../RbKit';

const AuthForm: FC = (): JSX.Element | null => {
    const [ emailAddress, setEmailAddress ] = useState<string>('');
    const [ errors, setErrors ] = useState<any>({});
    const [ password, setPassword ] = useState<string>('');
    const profile = useProfile();

    const login = (): void => {
        if (emailAddress === '' || password === '') {
            setErrors({ emailAddress: true, password: true });
            return;
        }

        setErrors({});

        api.login(emailAddress, password).then(({ data }) => {
            localStorage.setItem('accessToken', data.token as string);
            api.getProfile();
        }).catch(() => {
            setErrors({ incorrect: true });
        });
    }

    if (profile) {
        return null;
    }

    return (<>
        {errors.incorrect && (
            <Message
                content="Verkeerde gegevens"
                error
            />
        )}
        <Form onSubmit={(e) => { e.preventDefault(); login(); }}>
            <Form.Input
                error={errors.emailAddress}
                label="E-mailadres"
                onChange={({ value }: any) => setEmailAddress(value)}
                type="email"
                value={emailAddress}
            />
            <Form.Input
                error={errors.password}
                label="Wachtwoord"
                onChange={({ value }: any) => setPassword(value)}
                type="password"
                value={password}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    label="Nog geen account?"
                    link
                    href={'/registreren'}
                />
                <Button
                    label="Inloggen"
                    primary
                    type="submit"
                />
            </div>
        </Form>
    </>);
}

export default AuthForm;
