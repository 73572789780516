import { faHorizontalRule } from '@fortawesome/pro-regular-svg-icons';
import React, { FC } from 'react';
import { BlockProps, Segment } from '../../../../RbKit';
import styles from './styles.module.scss';

const Block: FC<BlockProps> = ({ front }): JSX.Element => {
    if (front) {
        return <div className={styles.divider} />;
    }

    return (
        <Segment>
            <div className={styles.divider} />
        </Segment>
    );
}

const data = {
    Block,
    icon: faHorizontalRule,
    getData: () => ({
        block: 'divider',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }],
    })
};

export default data;
