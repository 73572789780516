import { applyMiddleware, createStore, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import auth, { AuthState } from './reducers/auth';
import contenteditor, { ContentEditorState } from './reducers/contenteditor';
import lightbox, { LightboxState } from './reducers/lightbox';
import meta, { MetaState } from './reducers/meta';
import progress, { ProgressState } from './reducers/progress';

export interface AppState {
    auth: AuthState,
    contenteditor: ContentEditorState,
    lightbox: LightboxState,
    meta: MetaState,
    progress: ProgressState,
}

export default createStore(combineReducers({
    auth,
    contenteditor,
    lightbox,
    meta,
    progress,
}), applyMiddleware(thunkMiddleware));
