import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import api from '../../../api';
import { ApiBig } from '../../../api/big';
import { ApiPage } from '../../../api/page';
import CaptchaButton from '../../../components/front/CaptchaButton';
import Section from '../../../components/front/Section';
import Content from '../../../modules/front/Content';
import { Checkbox, Form, Grid, Segment, Table, Message } from '../../../RbKit';
import Loader from '../../../RbKit/elements/Loader';
import { setSettings } from '../../../reducers/contenteditor';
import { setCurrentModule } from '../../../reducers/progress';
import styles from './styles.module.scss';

interface RegisterViewProps extends RouteComponentProps<{ slug?: string }> {
}

let regTimer: any = undefined;

const PageView: FC<RegisterViewProps> = (): JSX.Element => {
    const [ regData, setRegData ] = useState<any>({});
    const [ errors, setErrors ] = useState<any>({});
    const [ bigData, setBigData ] = useState<ApiBig>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ success, setSuccess ] = useState<boolean>(false);
    const [ fetched, setFetched ] = useState<boolean>(false);
    const [ page, setPage ] = useState<ApiPage>();
    const [ bigError, setBigError ] = useState<string>();
    const [ showBig, setShowBig ] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCurrentModule());
    }, [dispatch]);

    useEffect(() => {
        api.front.getPageBySlug('registreren').then(({ data }) => {
            setFetched(true);
            setPage(data);
            dispatch(setSettings(data.settings || {}));
        }).catch(() => {
            setFetched(true);
        });
    }, [dispatch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setRegData({
            ...regData,
            [name]: value,
        });
    }

    const register = useCallback((token: string): void => {
        setIsLoading(true);
        setErrors({});

        api.register({ ...regData, token }).then(() => {
            setIsLoading(false);
            setSuccess(true);
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
        });
    }, [regData]);

    const handleBigCheck = (): void => {
        clearTimeout(regTimer);
        regTimer = setTimeout(() => {
            if (!regData.bigNumber || regData.bigNumber.length < 9) return;
            api.checkBig(regData.bigNumber.trim()).then(({ data }) => {
                setBigData(data);
                handleInput({ name: 'isProfessional', value: data.isPractioner});
            }).catch(() => setBigError('Dit BIG nummer is niet bekend in het BIG-register'));
        }, 500);
    }

    if (!fetched) {
        return <Loader />
    }

    return (<>
        {page && <Content blocks={page.content} settings={page.settings || {}} />}
        <Section alt>
            <div>
                <Segment className={styles.form} isLoading={isLoading}>
                    {success ? (
                        <Message
                            content="Uw registratie is voltooid, u ontvangt een e-mail met daarin een link waarmee u een wachtwoord kunt instellen voor uw account. Zodra u dit heeft gedaan, kunt u direct inloggen"
                            title="Registratie voltooid"
                            success
                        />
                    ) : (<>
                        <h3>Registratieformulier</h3>
                        <Grid.Row>
                            <Grid.Column md={4} style={{ marginBottom: '1.25rem' }}>
                                <Form.Input
                                    error={errors.first_name}
                                    label="Voornaam"
                                    name="firstName"
                                    onChange={handleInput}
                                    required
                                    value={regData?.firstName || ''}
                                />
                            </Grid.Column>
                            <Grid.Column md={3} className={styles.mid}>
                                <Form.Input
                                    label="Tussenv."
                                    name="affix"
                                    onChange={handleInput}
                                    value={regData?.affix || ''}
                                />
                            </Grid.Column>
                            <Grid.Column md={5} style={{ marginBottom: '1.25rem' }}>
                                <Form.Input
                                    error={errors.last_name}
                                    label="Achternaam"
                                    name="lastName"
                                    onChange={handleInput}
                                    required
                                    value={regData?.lastName || ''}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Form.Input
                            error={errors.email_address}
                            label="E-mailadres"
                            name="emailAddress"
                            onChange={handleInput}
                            required
                            value={regData?.emailAddress || ''}
                        />
                        <Form.Group style={{ marginBottom: '1rem' }}>
                                <span style={{ marginRight: '1rem' }}>
                                    Bent u BIG geregistreerd?
                                </span>
                                <Checkbox
                                    checked={showBig}
                                    label="Ja"
                                    radio
                                    onChange={() => setShowBig(true)}
                                />
                                <Checkbox
                                    checked={!showBig}
                                    label="Nee"
                                    radio
                                    onChange={() => setShowBig(false)}
                                />
                            </Form.Group>

                            {showBig && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flex: 1 }}>
                                        <Form.Input
                                            error={bigError}
                                            label="BIG nummer"
                                            name="bigNumber"
                                            onKeyUp={() => handleBigCheck()}
                                            onChange={handleInput}
                                            value={regData?.bigNumber || ''}
                                        />
                                    </div>
                                    {/* {regData.bigNumber && regData.bigNumber !== '' && (
                                        <Button
                                            label="Controleren"
                                            onClick={() => checkBig()}
                                            style={{ marginLeft: '1rem' }}
                                        />
                                    )} */}
                                </div>
                            )}
                            {bigData && (<>
                                <h3 style={{ margin: '1rem 0 .5rem 0' }}>Gegevens zoals gevonden in het BIG-register</h3>
                                <Table small>
                                    <tbody>
                                        <Table.Row>
                                            <Table.Cell collapsing>Naam</Table.Cell>
                                            <Table.Cell>{bigData.salutation} {bigData.fullName}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell collapsing>Beroep</Table.Cell>
                                            <Table.Cell>{bigData.profession}</Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell collapsing>Specialisme</Table.Cell>
                                            <Table.Cell>{bigData.specialism}</Table.Cell>
                                        </Table.Row>
                                        {bigData.mention !== false && (
                                            <Table.Row>
                                                <Table.Cell collapsing>Vermelding</Table.Cell>
                                                <Table.Cell>{bigData.mentionType}</Table.Cell>
                                            </Table.Row>
                                        )}
                                        <Table.Row>
                                            <Table.Cell collapsing>Beroepsbeoefenaar</Table.Cell>
                                            <Table.Cell>
                                                {bigData.isPractioner && <FontAwesomeIcon icon={faCheckCircle} className="success" />}
                                            </Table.Cell>
                                        </Table.Row>
                                    </tbody>
                                </Table>
                            </>)}

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                            <CaptchaButton
                                className="button"
                                label="Registreren"
                                onVerify={register}
                            />
                        </div>
                    </>)}
                </Segment>
            </div>
        </Section>
    </>);
}

export default PageView;
