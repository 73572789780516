import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown, faHome } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import api, { getToken } from '../../api';
import LessonLink from '../../components/front/LessonLink';
import { toggleNavigation } from '../front/Navigation';
import { useProfile } from '../../lib/auth';
import { useCurrentModule, useDeeplink, useProgress } from '../../lib/progress';
import logo from './logo.svg';
import logoWhite from './logo-white.svg';
import styles from './styles.module.scss';
import { Link, useHistory } from 'react-router-dom';
import { faExternalLink, faTrashAlt, faUser, faUserSecret } from '@fortawesome/pro-light-svg-icons';
import { openAuthModal } from '../../components/front/AuthModal';
import { useDispatch } from 'react-redux';
import { setProfile } from '../../reducers/auth';
import { find } from 'lodash';

const getShortName = (name: string): string => {
    const parts = name.split(' ');
    return `${parts[0][0]}${parts[parts.length - 1][0]}`;
}

interface TopBarProps {
    isFront?: boolean,
}

const TopBar: FC<TopBarProps> = ({ isFront }): JSX.Element => {
    const [ showBar, setShowBar ] = useState<boolean>(false);
    const profile = useProfile();
    const progress = useProgress();
    const deeplink = useDeeplink();
    const rmodule = useCurrentModule();
    const dispatch = useDispatch();
    const history = useHistory();

    const switchRole = (): void => {
        if (!profile) return;
        const subRole = profile.subRole === 'Gebruiker' ? 'Accrediteur' : 'Gebruiker';
        localStorage.setItem('sub-role', subRole);

        dispatch(setProfile({
            ...profile,
            subRole,
        }));
    }

    const deleteProgress = (): void => {
        if (window.confirm(progress.currentLesson ? 'Weet je zeker dat je de voortgang van deze les wilt verwijderen?' : 'Weet je zeker dat je de voortgang van deze module wilt verwijderen? De voortgang voor de individuele lessen zal ook worden verwijderd.')) {
            if (!profile) return;
            const type = progress.currentLesson !== undefined ? 'lesson' : 'module';
            const linked = find(profile.progress, { typeId: (progress.currentLesson?.id || progress.currentModule?.id), type });
            if (!linked) return;
            api.deleteProgress(linked.id).then(() => {
                api.getProfile().then(() => {
                    history.push(`/modules/${progress.currentModule?.id}`);
                });
            });
        }
    }

    return (<>
        <div className={`${styles.container} ${isFront ? styles.isFront : ''}`}>
            <div className={styles.innerContainer}>
                {isFront ? (
                    <div className={styles.hamburger}>
                        {deeplink && rmodule ? (
                            <Link to={`/modules/${rmodule.id}`}>
                                <FontAwesomeIcon
                                    icon={faHome}
                                />
                            </Link>
                        ) : (
                            <FontAwesomeIcon
                                icon={faBars}
                                onClick={() => toggleNavigation(true)}
                            />
                        )}
                        {!deeplink && <span style={{ marginLeft: '1rem' }}><b>SightLine</b>University</span>}
                    </div>
                ) : (
                    <div className={styles.title}>
                        <h1>{process.env.REACT_APP_APPLICATION_NAME}</h1>
                        {profile && (
                            <a
                                href={`${process.env.REACT_APP_BASE_URL}?_setToken=${getToken()}`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <FontAwesomeIcon
                                    icon={faExternalLink}
                                />
                            </a>
                        )}
                    </div>
                )}
                {progress.currentModule && progress.currentLesson && (
                    <Link
                        className={styles.module}
                        to={`/modules/${progress.currentModule.id}`}
                    >
                        {progress.currentModule.name}
                    </Link>
                )}
                <div className={styles.account}>
                    {profile ? (<>
                        {isFront && profile.role !== 'User' && (<>
                            <div
                                className={`${styles.switch} ${profile.subRole !== 'Gebruiker' ? styles.switchActive : ''}`}
                                onClick={() => switchRole()}
                            >
                                <FontAwesomeIcon icon={profile.subRole === 'Gebruiker' ? faUser : faUserSecret} />
                                {profile.role === 'Administrator' && profile.subRole !== 'Gebruiker' ? 'Admin' : (profile.subRole || 'Accrediteur')}
                            </div>
                            {(progress.currentModule || progress.currentLesson) && (
                                <div
                                    className={styles.switch}
                                    onClick={() => deleteProgress()}
                                >
                                    <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        style={{ margin: 0 }}
                                    />
                                    <div className="tooltip">
                                        Verwijder voortgang {progress.currentLesson ? 'les' : 'module'}
                                    </div>
                                </div>
                            )}
                        </>)}
                        <div
                            className={styles.avatar}
                            onClick={() => api.logout()}
                        >
                            {getShortName(`${profile.first_name} ${profile.last_name}`)}
                        </div>
                    </>) : (
                        <span
                            onClick={() => openAuthModal(window.location.href)}
                            style={{ color: '#ffffff', cursor: 'pointer', fontSize: 16 }}
                        >
                            Inloggen
                        </span>
                    )}
                </div>
                <img alt="Roche" className={styles.logo} src={isFront ? logoWhite : logo} />
            </div>
        </div>
        {progress.currentModule && progress.currentLesson && (
            <div className={`${styles.subBar} ${showBar ? styles.mobShow : ''}`}>
                <div className={styles.subBarBar} onClick={() => setShowBar(!showBar)}>
                    <span>{progress.currentLesson.name}</span>
                    <FontAwesomeIcon icon={faChevronDown} />
                </div>
                <div className={styles.subList}>
                    {progress.currentModule.lessons?.map((lesson) => (
                        <div onClick={() => setShowBar(false)}>
                            <LessonLink
                                key={`lesson-${lesson.id}`}
                                lesson={lesson}
                                protect={progress.currentModule?.isPublic === false}
                            />
                        </div>
                    ))}
                </div>
            </div>
        )}
    </>);
}

export default TopBar;
