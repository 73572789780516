import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from './';

export interface ApiChapter {
    id: number,
    moduleId: number,
    lessonId: number,
    name: string,
    content?: any[],
    settings?: { [key: string]: any },
    position: number,
}

const rest = {
    delete: (chapterId: number | string): AxiosPromise => {
        return axios.delete(`admin/chapters/${chapterId}`);
    },
    get: (chapterId: number): AxiosPromise<ApiChapter> => {
        return axios.get(`chapters/${chapterId}`);
    },
    list: (
        lessonId: number,
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiChapter[]>> => {
        return axios.get(`lessons/${lessonId}/chapters?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (chapter: Partial<ApiChapter>): AxiosPromise<ApiChapter> => {
        return axios.post(`chapters${chapter.id ? `/${chapter.id}` : ''}`, chapter);
    },
}

export default rest;
