import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { hasRole, useProfile } from '../../lib/auth';
import { Menu, Segment } from '../../RbKit';
import { useWindowResize } from '../../RbKit/lib/hooks';
import styles from './styles.module.scss';

export const collapse = (state?: boolean) => {
    dispatchEvent(new CustomEvent('toggle-drawer', { detail: { state }}));
}

export const useCollapse = () => {
    const [ isCollapsed, setIsCollapsed ] = useState(false);

    useEffect(() => {
        const handleCollapse = (e: any) => {
            if (e.detail && e.detail.state !== undefined) {
                setIsCollapsed(e.detail.state);
            } else {
                setIsCollapsed(!isCollapsed);
            }
        };
        window.addEventListener('toggle-drawer', handleCollapse);
        return () => window.removeEventListener('toggle-drawer', handleCollapse);
    });

    return isCollapsed;
}

const Drawer: FC = (): JSX.Element => {
    const isCollapsed = useCollapse();
    const location = useLocation();
    const profile = useProfile();
    const windowCollapse = () => collapse(window.innerWidth <= 992);
    useWindowResize(windowCollapse, 100);
    useEffect(windowCollapse, []);
    
    return (
        <Segment
            className={[
                styles.wrapper,
                isCollapsed && styles.isCollapsed,
            ].join(' ')}
            padding="none"
        >
            <div className={styles.base}>
                <Menu>
                    <Menu.Header>
                        Content
                    </Menu.Header>
                    <Menu.Item
                        active={location.pathname.includes('pages')}
                        className={styles.menuItem}
                        content="Pages"
                        href="/pages"
                    />
                    <Menu.Item
                        active={location.pathname.includes('modules')}
                        className={styles.menuItem}
                        content="Modules"
                        href="/modules"
                    />
                    <Menu.Item
                        active={location.pathname.includes('quizzes')}
                        className={styles.menuItem}
                        content="Quizzes"
                        href="/quizzes"
                    />
                </Menu>
                <Menu>
                    <Menu.Header>
                        Media library
                    </Menu.Header>
                    <Menu.Item
                        active={location.pathname.includes('media/images')}
                        className={styles.menuItem}
                        content="Images"
                        href="/media/images"
                    />
                    <Menu.Item
                        active={location.pathname.includes('media/documents')}
                        className={styles.menuItem}
                        content="Documents"
                        href="/media/documents"
                    />
                </Menu>
                {hasRole(profile, 'Administrator') && <Menu>
                    <Menu.Header>
                        Administrator
                    </Menu.Header>
                    <Menu.Item
                        active={location.pathname.includes('admin/templates')}
                        className={styles.menuItem}
                        content="Templates"
                        href="/admin/templates"
                    />
                    <Menu.Item
                        active={location.pathname.includes('admin/meta')}
                        className={styles.menuItem}
                        content="Meta"
                        href="/admin/meta"
                    />
                    <Menu.Item
                        active={location.pathname.includes('admin/users')}
                        className={styles.menuItem}
                        content="Users"
                        href="/admin/users"
                    />
                    <Menu.Item
                        active={location.pathname.includes('admin/progress')}
                        className={styles.menuItem}
                        content="Progress"
                        href="/admin/progress"
                    />
                </Menu>}
            </div>
        </Segment>
    );
}

export default Drawer;
