import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import TopBar from './modules/TopBar';
import ModuleView from './views/front/ModuleView';
import ModuleDetailView from './views/front/ModuleView/Detail';
import LessonView from './views/front/ModuleView/Lesson';
import PageView from './views/front/PageView';
import RegisterView from './views/front/RegisterView';
import ResetPasswordView from './views/front/ResetPasswordView';
import styles from './styles.module.scss';
import AuthModal from './components/front/AuthModal';
import Navigation from './modules/front/Navigation';
import LightboxModal from './modules/front/Lightbox';
import './default.scss';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const FrontApp: FC<{ showModule?: number }> = ({ showModule }): JSX.Element => {
    return (
        <Router>
            {!showModule && (<>
                <TopBar isFront />
                <Navigation />
            </>)}
            <main className={styles.mainFront}>
                <Switch>
                    {/* {showModule ? (<>
                        <Route component={LessonView} path="/modules/:id/lessons/:lessonId" />
                        <Route component={() => <ModuleDetailView showModule={showModule} />} path="*" />
                    </>) : (<> */}
                        <Route component={ModuleView} exact path="/modules" />
                        <Route component={ModuleDetailView} exact path="/modules/:id" />
                        <Route component={LessonView} exact path="/modules/:id/lessons/:lessonId" />

                        <Route component={ResetPasswordView} exact path="/auth/reset-password" />
                        <Route component={RegisterView} exact path="/registreren" />
                    {/* </>)} */}

                    <Route path="*" component={PageView} />
                </Switch>
                <div className={styles.footer}>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <div>
                                &copy; Roche Nederland {new Date().getFullYear()}
                            </div>
                            <a rel="noopener noreferrer" href="https://www.linkedin.com/company/roche?trk=hb_tab_compy_id_1602" target="_blank" style={{ fontSize: 24 }}>
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <a rel="noopener noreferrer" href="https://www.roche.nl/privacy-policy" target="_blank">Privacy policy</a>
                            <a rel="noopener noreferrer" href="https://www.roche.nl/legal-statement" target="_blank">Legal statement (gebruiksvoorwaarden)</a>
                            <a rel="noopener noreferrer" href="https://www.roche.nl/over-roche" target="_blank">Over Roche</a>
                            <a rel="noopener noreferrer" href="https://www.roche.nl/contact" target="_blank">Contact</a>
                        </div>
                    </div>
                </div>
            </main>
            {!showModule && <AuthModal />}
            <LightboxModal />
        </Router>
    )
}

export default FrontApp;
