import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import './RbKit/index.scss';

const isEmbed = document.getElementById('roche-academy-embed');

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            {/* <App isEmbed={isEmbed ? parseInt(`${isEmbed.getAttribute('data-module')}`) : false} /> */}
            <App isEmbed={false} />
        </Provider>
    </React.StrictMode>,
    isEmbed || document.getElementById('root')
);
