import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find, findIndex } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../../api';
import Button from '../../../../components/front/Button';
import { hasRole, useProfile } from '../../../../lib/auth';
import { useCurrentLesson, useCurrentModule, useObjectProgress } from '../../../../lib/progress';
import { secToMin } from '../../../../lib/util';
import Content from '../../../../modules/front/Content';
import Loader from '../../../../RbKit/elements/Loader';
import { useInterval } from '../../../../RbKit/lib/hooks';
import { setSettings } from '../../../../reducers/contenteditor';
import styles from './styles.module.scss';
import { faLock, faLockOpen, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

interface LessonViewProps {
}

const LessonView: FC<LessonViewProps> = (): JSX.Element => {
    const { id, lessonId } = useParams() as any;
    const [ timer, setTimer ] = useState<number>(0);
    const [ videoKeyPoint, setVideoKeyPoint ] = useState<number | boolean>();
    const [ videoKeyPointDone, setVideoKeyPointDone ] = useState<boolean>(false);
    const [ delay, setDelay ] = useState<number | null>(null);
    const rmodule = useCurrentModule(parseInt(id));
    const lesson = useCurrentLesson(parseInt(lessonId));
    const [ , progress ] = useObjectProgress('lesson', parseInt(lessonId));
    const dispatch = useDispatch();
    const history = useHistory();
    const profile = useProfile();

    useEffect(() => {
        if (lesson && rmodule && !rmodule.isPublic && !lesson.loggedIn) {
            history.push(`/modules/${rmodule.id}`);
        }
    }, [history, rmodule, lesson]);

    const videoDone = progress || hasRole(profile, 'Accreditor') || videoKeyPoint === undefined || videoKeyPointDone === true;
    const timerDone = progress || hasRole(profile, 'Accreditor') || (lesson?.timeOnPage || 0) <= 0 || (timer / (lesson?.timeOnPage || 100)) >= 1

    useEffect(() => {
        const handleVideoKeyPoint = ({ detail }: any): void => {
            setTimeout(() => {
                setVideoKeyPoint(detail.keyPoint);
                if (detail.keyPointDone === true) {
                    setVideoKeyPointDone(detail.keyPointDone);
                }
            }, 300);
        }
        
        setVideoKeyPoint(undefined);
        
        window.addEventListener('video-keypoint', handleVideoKeyPoint, true);
        return () => window.removeEventListener('video-keypoint', handleVideoKeyPoint, true);
    }, [id, lessonId]);

    useEffect(() => {
        if (!lesson || progress) return;
        if (lesson.timeOnPage > 0) {
            setDelay(timer < lesson.timeOnPage ? 1000 : null);
        } else {
            setDelay(null);
        }
    }, [lesson, timer, progress]);

    useInterval(() => {
        setTimer((t) => t + 1);
    }, delay);

    const updateProgress = useCallback((): void => {
        if (!lesson || !rmodule) return;
        if (rmodule.isPublic && !profile) {
            return;
        }
        const prevLesson: any = find(rmodule.lessons, { position: lesson.position - 1 });
        const progress: any = find(profile?.progress, { type: 'lesson', typeId: prevLesson?.id } as any);
        
        if (
            lesson.position === 0 ||
            (progress && progress.percentage >= 100)
        ) {
            api.front.progress.upsert('lesson', lesson.id).catch(() => {
                history.push(`/modules/${rmodule.id}`);
            });
        } else {
            if (!hasRole(profile, 'Accreditor')) {
                history.push(`/modules/${rmodule.id}${prevLesson ? `/lessons/${prevLesson.id}` : ''}`);
            }
        }
    }, [lesson, rmodule, profile, history]);

    useEffect(() => {
        setTimer(0);
        setVideoKeyPoint(undefined);
        setVideoKeyPointDone(false);
        updateProgress();
        dispatch(setSettings(lesson?.settings || {}));
    }, [lesson]); // eslint-disable-line

    const handleNextLesson = (): void => {
        if (!rmodule || !lesson) return;
        const nextLesson = find(rmodule.lessons, { position: lesson.position + 1 });

        if (nextLesson) {
            if (!profile) {
                history.push(`/modules/${rmodule.id}/lessons/${nextLesson.id}`);
                return;
            }

            api.front.progress.upsert('lesson', lesson.id, 100).then(() => {
                history.push(`/modules/${rmodule.id}/lessons/${nextLesson.id}`);
            });
        }
    }

    if (!lesson) {
        return <Loader />
    }

    return (<div style={{ marginTop: 50 }}>
        {lesson.content && (
            <Content
                blocks={lesson.content}
                settings={lesson.settings || {}}
                lessonDone={progress || hasRole(profile, 'Accreditor')}
            />
        )}

        {(videoKeyPoint !== undefined || lesson.timeOnPage > 0) && (
            <div className={styles.restrictions}>
                {videoKeyPoint !== undefined && (
                    <div className={styles.restriction}>
                        <FontAwesomeIcon
                            icon={videoDone ? faCheckCircle : faTimesCircle}
                            className={videoDone ? 'success' : undefined}
                        />
                        Bekijk minimaal {secToMin(parseInt(`${videoKeyPoint || 0}`))} van de video.
                    </div>
                )}
                {lesson.timeOnPage > 0 && (
                    <div className={styles.restriction}>
                        <FontAwesomeIcon
                            icon={timerDone ? faCheckCircle : faTimesCircle}
                            className={timerDone ? 'success' : undefined}
                        />
                        Breng minimaal {secToMin(lesson.timeOnPage - (timerDone ? 0 : timer))} op deze pagina door.
                    </div>
                )}
            </div>
        )}

        {findIndex(rmodule?.lessons, { position: lesson.position }) < (rmodule?.lessons?.length || 0) - 1 && (
            <div className={styles.button}>
                <Button
                    icon={videoDone && timerDone ? faLockOpen : faLock}
                    label={`Doorgaan naar lesblok ${lesson.position + 2}`}
                    primary
                    onClick={handleNextLesson}
                    disabled={!videoDone || !timerDone}
                />
            </div>
        )}
    </div>);
}

export default LessonView;
