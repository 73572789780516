import { faRepeatAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC } from 'react';
import parse from 'html-react-parser';
import { getValue } from '../../../../lib/block';
import { BlockProps, Segment } from '../../../../RbKit';
import textStyles from '../Text/styles.module.scss';
import styles from './styles.module.scss';
import { fitImage } from '../../../../lib/image';

const Block: FC<BlockProps> = ({ data, front }): JSX.Element => {
    const backImage = getValue(data, 'back-image');
    const frontImage = getValue(data, 'front-image');

    const block = (
        <div className={styles.flipCard}>
            <div className={styles.flipCardInner}>
                <div className={styles.flipCardFront}>
                    {frontImage ? (
                        <img src={fitImage(frontImage.src, 278, 278)} alt="" />
                    ) : parse(getValue(data, 'front') || '')}
                </div>
                <div className={styles.flipCardBack}>
                    {backImage ? (
                        <img src={fitImage(backImage.src, 278, 278)} alt="" />
                    ) : parse(getValue(data, 'back') || '')}
                </div>
            </div>
        </div>
    )

    return front ? block : (
        <Segment className={`${textStyles.container} ${styles.container}`}>
            {block}
        </Segment>
    );
}

const data = {
    Block,
    icon: faRepeatAlt,
    getData: () => ({
        block: 'fliptext',
        editorCss: '.mceContentBody { margin: 0; padding: 16px; color: #313537; } h1 {margin: 0 0 32px 0;font-size: 36px;} a {color: #4baaf4;} p, ul, ol {font-size: 18px;line-height: 1.7;margin-bottom: 32px;font-weight: 300;} h2 {font-size: 18px;line-height: 1.9;margin-bottom: 0 !important;} p + ul, p + ol { margin-top: -24px; }',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'front',
            type: 'wysiwyg',
            label: 'Front',
            value: '',
        }, {
            id: 'front-image',
            type: 'image',
            label: 'Front image',
            value: '',
        }, {
            id: 'back',
            type: 'wysiwyg',
            label: 'Back',
            value: '',
        }, {
            id: 'back-image',
            type: 'image',
            label: 'Back image',
            value: '',
        }],
    })
};

export default data;
