import { faInfo } from '@fortawesome/pro-regular-svg-icons';
import React, { FC } from 'react';
import { BlockProps } from '../../../../RbKit';
import { useCurrentModule } from '../../../../lib/progress';
import ModuleMeta from '../../../../components/front/ModuleMeta';

const Block: FC<BlockProps> = ({ data, front }) => {
    const rmodule = useCurrentModule();

    if (!rmodule) {
        return front ? null : <div>Module meta</div>
    }

    return (
        <div style={{ marginBottom: 32 }}>
            <h1 style={{ marginBottom: '.5rem' }}>{rmodule.name}</h1>
            <p>{rmodule.excerpt}</p>
            <ModuleMeta rmodule={rmodule} />
        </div>
    );
}

const data = {
    Block,
    icon: faInfo,
    getData: () => ({
        block: 'modulemeta',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }],
    }),
};

export default data;
