import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import React, { FC, useCallback, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Confirm, Menu, PageHeader, Segment, Table, toast } from '../../../RbKit';
import api from '../../../api';
import moment from 'moment';

interface QuizProgressViewProps extends RouteComponentProps<{ id: string }> {
}

const QuizProgressView: FC<QuizProgressViewProps> = ({ match }) => {
    const { id } = match.params;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ items, setItems ] = useState<any[]>([]);

    const fetch = useCallback((query?: string): void => {
        setIsLoading(true);

        api.listQuizProgress(parseInt(id), { query }).then(({ data }) => {
            setItems(data);
            setIsLoading(false);
        });
    }, [id]);

    const deleteProgress = (progressId: number): void => {
        api.deleteProgress(progressId).then(() => {
            fetch();
            toast('Progress deleted successfully');
        });
    }

    return (<>
        <PageHeader
            title="Quiz results"
            breadcrumb={{
                '/quizzes': 'Quizzes',
                [`/quizzes/${id}/edit`]: 'Results',
            }}
        />
        <Segment isLoading={isLoading}>
            <Table.Actions
                autoLoad
                onSearch={(q) => fetch(q)}
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Name
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing  align="right">
                            Progress
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing  align="right">
                            Score
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            Tries
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            Last update
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {items.length > 0 ? items.map((item) => (
                        <Table.Row key={`row-${item.id}`}>
                            <Table.Cell primary name="Name">
                                <Link to={`/admin/users/${item.user?.id}/edit`}>
                                    {item.user?.firstName} {item.user?.lastName}
                                </Link>
                            </Table.Cell>
                            <Table.Cell collapsing align="right" name="Percentage">
                                {item.progress}%
                            </Table.Cell>
                            <Table.Cell collapsing align="right" name="Score">
                                {item.score}%
                                {item.progress >= 100 && (
                                    <FontAwesomeIcon
                                        icon={item.score >= item.minScore ? faCheckCircle : faTimesCircle}
                                        className={item.score >= item.minScore ? "success" : "error"}
                                        style={{ marginLeft: 8 }}
                                    />
                                )}
                            </Table.Cell>
                            <Table.Cell collapsing name="Tries" align="right">
                                {item.tries} / {item.maxTries}
                            </Table.Cell>
                            <Table.Cell collapsing name="Last update">
                                {moment(item.updatedAt).format('DD/MM/YYYY - HH:mm')}
                            </Table.Cell>
                            <Table.Cell collapsing actions>
                                <Menu dropdown>
                                    <Confirm
                                        content="Are you sure you wish to delete this progress? This action cannot be undone"
                                        onConfirm={() => deleteProgress(item.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Delete"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
    </>);
}

export default QuizProgressView;
