import { faText } from '@fortawesome/pro-solid-svg-icons';
import React, { FC } from 'react';
import parse from 'html-react-parser';
import { getValue } from '../../../../lib/block';
import { BlockProps, Segment } from '../../../../RbKit';
import ContentEditor from '../..';
import styles from '../Text/styles.module.scss';

const Block: FC<BlockProps> = ({ data, onBlockChange }): JSX.Element => {
    const handleUpdate = (items: any[]): void => {
        const newData = { ...data };
        newData.fields[1].value = items;
        if (onBlockChange) {
            onBlockChange(newData);
        }
    }

    return (
        <Segment className={styles.container} style={{ paddingBottom: 0 }}>
            <div>
                {parse(getValue(data, 'question') || '')}
                <div style={{ padding: '1rem' }}>
                    <ContentEditor
                        content={{ blocks: getValue(data, 'answers') } }
                        onUpdate={handleUpdate}
                        availableBlocks={['Answer']}
                        addLabel="Add answer"
                    />
                </div>
            </div>
        </Segment>
    );
}

const data = {
    Block,
    icon: faText,
    getData: () => ({
        block: 'question',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'question',
            type: 'wysiwyg',
            label: 'Question',
            value: '',
        }, {
            id: 'answers',
            type: 'contenteditor',
            label: 'Answers',
            value: [],
        }],
    })
};

export default data;
