import { faImage } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { ApiImage } from '../../api/image';
import { fitImage } from '../../lib/image';
import { openImageModal } from '../ImageModal';
import styles from './styles.module.scss';

const PLACEHOLDER =  `${process.env.REACT_APP_API_BASE_URL}media/placeholder.png`;

interface ImageThumbProps {
    confirm?: string,
    image?: Partial<ApiImage>,
    onDelete?: (imageId: number) => void;
    square?: boolean,
    width?: number,
    detail?: boolean,
}

const ImageThumb: FC<ImageThumbProps> = ({ confirm, detail, image, onDelete, square, width }): JSX.Element => {
    const [ extraStyle, setExtraStyle ] = useState<any>({});
    useEffect(() => {
        if (square) {
            setExtraStyle({
                height: width || 150,
                paddingBottom: 0,
                width,
            });
        }
    }, [square, width]);

    return (
        <div
            className={styles.thumbnail}
            style={{
                backgroundImage: `url(${fitImage(image ? image.src || PLACEHOLDER : PLACEHOLDER, 200, 200)})`,
                cursor: 'pointer',
                ...extraStyle,
            }}
            onClick={() => detail && openImageModal(image?.id || 0)}
        >
            {!image && <FontAwesomeIcon
                className={styles.placeholder}
                icon={faImage}
            />}
        </div>
    );
}

export default ImageThumb;
