import { faCalendarAlt, faClock, faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { ApiModule } from '../../../api/module';
import { renderMeta } from '../../../lib/meta';
import styles from './styles.module.scss';

interface ModuleMetaProps {
    rmodule: ApiModule,
}

const ModuleMeta: FC<ModuleMetaProps> = ({ rmodule }): JSX.Element => {
    return (
        <div className={styles.container}>
            {renderMeta(rmodule.meta || [], 'Tijdsduur', (meta) => (
                <p>
                    <span>
                        <FontAwesomeIcon icon={faClock} />
                    </span>
                    {meta.name}
                </p>
            ))}
            <p>
                <span>
                    <FontAwesomeIcon icon={faCopy} />
                </span>
                {rmodule.lessons ? rmodule.lessons.length : rmodule.info?.lessonCount} lesblokken
            </p>
        </div>
    );
}

export default ModuleMeta;
