import { faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find } from 'lodash';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Checkbox, Grid, Form, PageHeader, Segment, toast, Button } from '../../../RbKit';
import api from '../../../api';
import { emptyMeta, ApiMeta } from '../../../api/meta';
import ChangeHandler from '../../../components/ChangeHandler';
import SortableList, { reorder } from '../../../components/SortableList';
import { useMeta } from '../../../lib/meta';
import styles from './styles.module.scss';
import MediaSelector from '../../../components/MediaSelector';
import { ApiImage } from '../../../api/image';

interface UserEditProps extends RouteComponentProps<{ id?: string }> {
}

const UserEditView: FC<UserEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ sorted, setSorted ] = useState<Partial<ApiMeta>[]>();
    const [ errors, setErrors ] = useState<any>({});
    const [ refresh, setRefresh] = useState<boolean>(false);
    const [ item, setItem ] = useState<Partial<ApiMeta>>({});
    const [ image, setImage ] = useState<ApiImage>();
    const [ changesMade, setChangesMade ] = useState<boolean>(false);
    const meta: ApiMeta[] = useMeta(refresh);
    
    const handleEdit = useCallback(() => {
        setChangesMade(false);
        if (!id) return;
        const current = find(meta, { id: parseInt(id) }) as ApiMeta;
        if (current) {
            setItem(current);
            setSorted(current.children || []);
        }
    }, [id, meta]);

    const onDragEnd = (result: any): void => {
        if (result.destination && sorted) {
            setChangesMade(true);
            setSorted(reorder(sorted, result.source.index, result.destination.index));
        }
    }
    useEffect(() => {
        setSorted(item.children || []);
        if(!image && item.image) {
            setImage(item.image);
        }
    }, [item, image]);

    useEffect(() => {
        handleEdit();
    }, [handleEdit]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setItem({
            ...item,
            [name]: value,
        });
    }

    const addOption = (): void => {
        setChangesMade(true);
        setItem({
            ...item,
            children: [
                ...(item.children || []),
                Object.assign({ id: `new-${Math.random()}` }, emptyMeta),
            ],
        });
    }

    const handleOption = (index: number, value: string): void => {
        const newOptions = [...(item.children || [])];
        newOptions[index].name = value;
        setChangesMade(true);
        setItem({
            ...item,
            children: newOptions,
        });
    }

    const removeOption = (index: number): void => {
        setChangesMade(true);
        setItem({
            ...item,
            children: (item.children || []).filter((o, i) => i !== index),
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        
        api.putMeta({
            ...item,
            children: sorted,
        }).then(() => {
            setChangesMade(false);
            toast('User saved succesfully');
            setRefresh(true);
            history.push('/admin/meta');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast('Something went wrong', 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={handleEdit}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/admin/meta': 'Meta',
                [`/admin/meta/${id ? `${id}/edit` : 'create'}`]: id ? item.name || 'New' : 'New',
            }}
            title={`${id ? 'Edit' : 'Create'} meta`}
        />
        <Grid.Row>
            <Grid.Column md={6}>
                <Form onSubmit={(e: FormEvent) => save(e)}>
                    <Segment>
                        <Form.Group>
                            <Checkbox
                                checked={item.showInFilter}
                                label="Show in filter"
                                onChange={({ checked }: any) => handleInput({ name: 'showInFilter', value: checked })}
                            />
                        </Form.Group>
                        <Form.Input
                            error={errors.name}
                            label="Label"
                            name="name"
                            onChange={handleInput}
                            required
                            value={item.name || ''}
                        />
                        <Form.Textarea
                            label="Omschrijving"
                            name="description"
                            onChange={handleInput}
                            rows={4}
                            value={item.description || ''}
                        />
                        <Form.Input
                            label="Kleur"
                            name="color"
                            type="color"
                            onChange={handleInput}
                            value={item.color || '#0066cc'}
                            info={item.color || '#0066cc'}
                        />
                        <Form.Group>
                            <label>Image</label>
                            <MediaSelector
                                onSelect={(img) => {
                                    handleInput({ name: 'imageId', value: img.id })
                                    setImage(img);
                                }}
                                thumb={image}
                            />
                        </Form.Group>
                    </Segment>
                </Form>
            </Grid.Column>
            <Grid.Column md={6}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                    <h2 style={{ margin: 0 }}>Options</h2>
                    <Button
                        icon={faPlus}
                        onClick={() => addOption()}
                        primary
                    />
                </div>
                {sorted && (
                    <SortableList
                        items={sorted}
                        onUpdate={onDragEnd}
                        renderListItem={(o, index) => (
                            <Segment
                                className={styles.sortable}
                                padding="dense"
                            >
                                <div className={styles.handle}>
                                    <FontAwesomeIcon icon={faGripVertical} />
                                </div>
                                <div className={styles.sortableFlex}>
                                    <Form.Input
                                        label="Label"
                                        name="name"
                                        onChange={({ value }: any) => handleOption(index, value)}
                                        value={o.name}
                                    />
                                </div>
                                <Button
                                    icon={faTrashAlt}
                                    onClick={() => removeOption(index)}
                                    trigger
                                />
                            </Segment>
                        )}
                    />
                )}
            </Grid.Column>
        </Grid.Row>
    </>);
}

export default UserEditView;
